import React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  SimpleList,
  List,
  Datagrid,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  // SelectInput,
  SearchInput,
  downloadCSV,
  // DateInput,
  DateField,
  FunctionField,
} from "react-admin";
import { PageTitle, ListActions } from "../../components";
import jsonExport from "jsonexport/dist";
import { maskPhone } from "../../utils/maskInputs";

const FilterUser = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    {/* <SelectInput
      source="group"
      label="Grupo"
      choices={[{ id: "0", name: "Todos" }]}
    /> */}
  </Filter>
);
const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      id,
      name,
      cell_phone,
      email,
      created_at,
      image,
      group,
      ...postForExport
    } = user;
    return postForExport;
  });

  jsonExport(
    usersForExport,
    {
      headers: [
        "id",
        "name",
        "cell_phone",
        "email",
        "created_at",
        "image",
        "group",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "users");
    }
  );
};

const UserList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterUser />}
      filterDefaultValues={{ in_group: [2] }}
      actions={<ListActions isCreate />}
      exporter={exporter}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ email }) => email}
          tertiaryText={({ cell_phone }) => cell_phone}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" emptyText="Não informado" />
          <TextField label="Nome" source="name" />
          <TextField
            label="Grupo"
            source="group.name"
            emptyText="Não informado"
          />
          <FunctionField
            label="Telefone"
            render={(record) => `${maskPhone(record?.cell_phone)}`}
            source="cell_phone"
            emptyText="Não informado"
          />
          <EmailField label="e-mail" source="email" emptyText="Não informado" />
          <DateField
            label="Data entrada"
            source="created_at"
            emptyText="Não informado"
          />
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default UserList;
