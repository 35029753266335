import ReceiptIcon from '@mui/icons-material/Receipt';
import InvoicesList from "./InvoicesList";
import InvoicesEdit from "./InvoicesEdit";

const BanksView = {
  name: 'invoices',
  options: { label: 'Notas Fiscais', singularLabel: 'Notas Fiscais' },
  list: InvoicesList,
  edit: InvoicesEdit,
  icon: ReceiptIcon,
};

export default BanksView
