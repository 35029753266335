import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Login } from "react-admin";
import LoginForm from "./LoginForm";
import { bg } from "../../utils/bg";
import { createTheme } from '@mui/material';

const useStyles = makeStyles(() => ({
  buttonView: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
  },
}));

const theme = createTheme({
  palette: {
    background: {default: '#F5F5F5', },
    secondary: {main: '#F0781E'},
    primary: {main: '#F0781E'},
  },
});

export const MyLoginPage = () => {
  const classes = useStyles();
  return (
    <Login theme={theme} backgroundImage={bg()}>
      <LoginForm />

      <div className={classes.buttonView}>
        <Button
         
          onClick={() => (window.location.href = "/forgot_password")}
          style={{ color: theme.palette.primary.main }}
        >
          Esqueci minha senha
        </Button>
      </div>
    </Login>
  );
};
