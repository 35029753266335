/* eslint-disable react/prop-types */
import * as React from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";
import { TopToolbar, ListButton } from "react-admin";

const EditPageActions = (props) => (
  <TopToolbar>
    <ListButton
      basePath={props.basePath}
      label="Voltar"
      icon={<ChevronLeft />}
    />
    {props.hasEdit && props.data && (
      <ListButton
        basePath={`${props.basePath}/${props.data.id}`}
        label="Alterar"
        icon={<EditIcon />}
      />
    )}
  </TopToolbar>
);

export default EditPageActions;
