import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Button } from '@mui/material';
import Description  from '@material-ui/icons/Description';
import {
  SimpleList,
  List,
  Datagrid,
  DateInput,
  TextField,
  EditButton,
  DateField,
  Filter,
  SelectInput,
  SearchInput,
  FunctionField,
  useRecordContext
} from 'react-admin';
import { PageTitle, ListActions } from '../../components';
import { toCurrency, statusAsaas } from '../../utils/maskInputs';

const FilterInvoices = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="status"
      label="Status"
      choices={[
        { id: 'PENDING', name: 'Pendente' },
        { id: 'SUCCESS', name: 'Aprovada' },
        { id: 'REJECTED', name: 'Rejeitada' },
      ]}
    />
    <DateInput source="reference_date" label="Data de referência" />
  </Filter>
);

const ButtonView = ({ source }) => {
  const record = useRecordContext();
  return (
    <Button type="button"  onClick={() => window.open(record.file_url, '_blank')}>
      <Description  style={{ marginRight: 5 }} onClick={() => window.open(record.file_url, '_blank')} />
      Ver nota fiscal
    </Button>
  );
};

const BanksList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterInvoices />}
      actions={<ListActions />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ status }) => status}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" emptyText="Não informado"/>
          <TextField label="Corretora" source="partner.name" emptyText="Não informado"/>
          <FunctionField
            label="Valor" 
            render={record => toCurrency(record.value)}
              source="value" emptyText="Não informado"
          />
          <DateField source="reference_date" label="Data de referência" />
          <FunctionField
            label="Status" 
            render={record => statusAsaas(record.status)}
              source="status" emptyText="Não informado"
          />
          <div>
            <ButtonView record />
          </div>
          <EditButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default BanksList;
