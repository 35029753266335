/* eslint-disable react/prop-types */
import * as React from "react";
import { Box, Toolbar } from "@material-ui/core";
import { SaveButton, DeleteButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  muiButton: {
    color: "#ffffff",
  },
}));

const EditPageToolbar = ({
  handleSubmitWithRedirect,
  noHandleSubmit,
  handleSubmit,
  invalid,
  saving,
  basePath,
  record,
  resource,
  noDelete = false,
}) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        {!noHandleSubmit ? (
          <SaveButton
            className={classes.muiButton}
            handleSubmit={handleSubmit}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            submitOnEnter
            redirect={basePath}
          />
        ) : (
          <></>
        )}
        {!noDelete && (
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
          />
        )}
      </Box>
    </Toolbar>
  );
};

export default EditPageToolbar;
