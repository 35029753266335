import * as React from "react";
import { Typography, Box } from "@material-ui/core";
import { sanitizeListRestProps } from "react-admin";

const EmptyData = (props) => {
  const { text, ...rest } = props;
  return (
    <Box
      justifyContent="center"
      height="50em"
      width="87%"
      display="flex"
      alignContent="center"
      alignItems="center"
      alignSelf="center"
      {...sanitizeListRestProps(rest)}
    >
      <Typography variant="h6" gutterBottom>
        {text || "Nenhum Resultado encontrado"}
      </Typography>
    </Box>
  );
};

export default EmptyData;
