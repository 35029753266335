/* eslint-disable react/prop-types */
import React from "react";
import { Typography, Box } from "@material-ui/core";
import {
  Edit,
  TextInput,
  ImageField,
  BooleanInput,
  FormWithRedirect,
} from "react-admin";
import { ReturnActions, EditPageToolbar, PageTitle } from "../../components";

const AdsEdit = (props) => {
  const toPrice = (price) => {
    return price?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };
  return (
    <Edit
      title={
        <PageTitle
          label={props.options.singularLabel}
          type="edit"
          record={props.record}
        />
      }
      actions={<ReturnActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={({
          basePath,
          handleSubmitWithRedirect,
          invalid,
          resource,
          saving,
          record,
        }) => (
          <form>
            <Box p="1em">
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Informações do Anúncio
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        disabled
                        format={toPrice}
                        label="Preço"
                        source="price"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} mt="-2em" ml="1em">
                      <Typography variant="h6" gutterBottom>
                        Alterar status
                      </Typography>
                      <Box height="5em" display="flex" alignItems="center">
                        <BooleanInput label="Ativo" source="status" fullWidth />
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" ml="0.5em">
                    <TextInput
                      disabled
                      label="Descrição"
                      source="description"
                      type="text"
                      multiline
                      fullWidth
                    />
                  </Box>

                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Informações do carro anunciado
                      </Typography>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Marca"
                            source="car.brand.name"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Modelo"
                            source="car.model.name"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Cor"
                            source="car.color.name"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Combustível"
                            source="car.fuel"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Câmbio"
                            source="car.gearbox"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} height="5em">
                          <TextInput
                            disabled
                            label="Quilometragem"
                            type="number"
                            source="car.mileage"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        
                        <Box flex={1} height="5em">
                          <TextInput
                            disabled
                            label="Ano"
                            type="number"
                            source="car.reg_date"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Motor"
                            source="car.motor_power"
                            fullWidth
                          />
                        </Box>
                        
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Tipo"
                            source="car.type"
                            fullWidth
                          />
                        </Box>
                        
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Endereço do anúncio
                      </Typography>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Cidade"
                            source="address.city.name"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Estado"
                            source="address.city.state.name"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="UF"
                            source="address.city.state.uf"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="CEP"
                            source="address.code_post"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Bairro"
                            source="address.district"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Rua"
                            source="address.street"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Número"
                            type="number"
                            source="address.number"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" height="5em">
                          <TextInput
                            disabled
                            label="Complemento"
                            source="address.complement"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <ImageField
                      disabled
                      label="Imagens"
                      source="images[0].image.medium"
                      type="text"
                      emptyText="Sem imagens cadastradas"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <EditPageToolbar
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              basePath={basePath}
              saving={saving}
              record={record}
              resource={resource}
            />
          </form>
        )}
      />
    </Edit>
  );
};

export default AdsEdit;
