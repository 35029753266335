import BrokerIcon from '@material-ui/icons/Group';
import ProducerCreate from './ProducerCreate';
import ProducerEdit from './ProducerEdit';
import ProducerList from './ProducerList';

const ProducerView = {
  name: 'producers',
  options: { label: 'Produtores', singularLabel: 'Produtor', "menuParent": "brokerages" },
  list: ProducerList,
  edit: ProducerEdit,
  create: ProducerCreate,
  icon: BrokerIcon,
};

export default ProducerView
