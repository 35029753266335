/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { useApi } from "../context";

const useStyles = makeStyles({
  all: {
    color: 'white',
  },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyUserMenu = props => {
    const { apiUrl, fetchApi } = useApi();
    const [identity, setIdentity] = useState({});

    const getBalance = async () => {
        try {
            const url = `${apiUrl.replace('admin','client')}`;
            const response = await fetchApi(url);
            const responseJson = await response.json();
            if (response.status !== 200) {
            throw response;
            }
            setIdentity(responseJson.data);
        } catch (err) {
            console.warn(err);
        }
    };

    useEffect(async () => {
        await getBalance()
    }, [])

    return (
        <UserMenu {...props}>
            <MenuItemLink
                to={`/users/${identity?.id}`}
                primaryText="Perfil"
                leftIcon={<SettingsIcon />}
            />
        </UserMenu>
    );
}

const MyAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar className={classes.all} userMenu={<MyUserMenu />} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            Autoconect
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default MyAppBar;