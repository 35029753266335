import React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  SimpleList,
  List,
  Datagrid,
  // EmailField,
  TextField,
  FunctionField,
  EditButton,
  // DeleteButton,
  Filter,
  // SelectInput,
  SearchInput,
  downloadCSV,
  // DateInput,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
} from "react-admin";
import { PageTitle, ListActions, EmptyData } from "../../components";
import jsonExport from "jsonexport/dist";

const FilterAds = (props) => (
  <Filter {...props}>
    <SearchInput 
      source="q" 
      alwaysOn 
      placeholder="Buscar por marca ou modelo" 
      style={{ width:'350px' }}
    />
    <ReferenceInput alwaysOn label="Corretoras" reference="brokerage_agencies" source="partner_id">
      <AutocompleteInput fullWidth optionText="name" />
    </ReferenceInput>
    <ReferenceInput alwaysOn label="Produtores" reference="brokerage_users" filter={{in_group: [3,4], partner_type:'broker_partner'}} source="user_id">
      <AutocompleteInput fullWidth optionText="name" />
    </ReferenceInput>
    <ReferenceInput alwaysOn label="Parceiros" reference="partners" source="partner_id">
      <AutocompleteInput fullWidth optionText="name" />
    </ReferenceInput>
    <DateInput alwaysOn source="created_at_after" label="De" />
    <DateInput alwaysOn source="created_at_before" label="Até" />
  </Filter>
);
const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      cell_phone,
      status,
      cpf,
      social_media,
      config,
      image,
      group,
      hash_id,
      ...postForExport
    } = user;
    return postForExport;
  });

  jsonExport(
    usersForExport,
    {
      headers: ["id", "name", "email", "created_at"],
    },
    (err, csv) => {
      downloadCSV(csv, "users");
    }
  );
};

const AdsList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      data-cy="listAnuncios"
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterAds />}
      actions={<ListActions />}
      exporter={exporter}
      empty={<EmptyData text="Não existem anúncios cadastrados" />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ email }) => email}
          tertiaryText={({ cell_phone }) => cell_phone}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" />
          <TextField
            label="Corretora"
            source="user.partner.name"
            emptyText="Não informado"
          />
          <TextField
            label="Produtor"
            source="user.name"
            emptyText="Não informado"
          />
          <TextField
            label="Marca"
            source="car.brand.name"
            emptyText="Não informado"
          />
          <TextField
            label="Modelo"
            source="car.model.name"
            emptyText="Não informado"
          />
          <TextField
            label="Ano"
            source="car.reg_date"
            emptyText="Não informado"
          />
          <FunctionField
            label="Valor"
            render={(record) =>
              `${record.price?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}`
            }
            source="price"
            emptyText="Não informado"
          />
          <TextField
            label="Estado"
            source="address.city.state.name"
            emptyText="Não informado"
          />
          <TextField
            label="Cidade"
            source="address.city.name"
            emptyText="Não informado"
          />
          <DateField
            label="Data entrada"
            source="created_at"
            emptyText="Não informado"
          />
          <EditButton label="" />
          {/* <DeleteButton label="" /> */}
        </Datagrid>
      )}
    </List>
  );
};

export default AdsList;
