import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Edit, TextInput, BooleanInput, FormWithRedirect } from 'react-admin';

import {
  ReturnActions,
  EditPageToolbar,
  PageTitle,
} from '../../components';

const BrokerEdit = (props) => {
const typeAccount = val => {
  return val ==='broker_partner' && 'Produtor'
}
return (
  <Edit
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="edit"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({
        basePath,
        handleSubmitWithRedirect,
        invalid,
        resource,
        saving,
        record,
      }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Alterar corretora
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput disabled label="Nome" source="name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput disabled label="Telefone" source="cell_phone" fullWidth />
                  </Box>
                  <Box flex={1} mt="-2em" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Status
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput label="Ativo" source="status" fullWidth />
                    </Box>
                </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="CNPJ"
                      source="ean"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Susep"
                      source="susep"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Grupo de usuário"
                      format={typeAccount}
                      source="type"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail"
                      source="email"
                      type="email"
                      fullWidth
                    />
                  </Box>
                  {/* <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Senha"
                      source="password"
                      type="password"
                      fullWidth
                    />
                  </Box> */}
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Alterar endereço da corretora
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput disabled label="Cidade" source="address.city.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput disabled label="Estado" source="address.city.state.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput disabled label="UF" source="address.city.state.uf" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="CEP"
                      source="address.code_post"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Bairro"
                      source="address.district"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Rua"
                      source="address.street"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Número"
                      type="number"
                      source="address.number"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Complemento"
                      source="address.complement"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <EditPageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            basePath={basePath}
            saving={saving}
            record={record}
            resource={resource}
          />
        </form>
      )}
    />
  </Edit>
);
}

export default BrokerEdit;
