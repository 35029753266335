/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-var-requires */

import small from "../assets/background.jpg";
import mobile from "../assets/background.jpg";
import ipad from "../assets/background.jpg";
import desktop from "../assets/background.jpg";

export const bg = () => {
  const screen = window.screen.width;
  if (screen < 400) {
    return small;
  }
  if (screen <= 600 && screen > 360) {
    return mobile;
  }
  if (screen <= 800 && window.screen.height <= 1024) {
    return ipad;
  } else {
    return desktop;
  }
};
