import moment from "moment";

export const stringfyDate = (resource = "", payload = {}) => {
  if (
    (resource === "notifications" || resource === "posts") &&
    payload.date_scheduled
  ) {
    payload.date_scheduled.toJSON = () =>
      moment(payload.date_scheduled).format();
  }
  return payload;
};
