/* eslint-disable react/prop-types */
import React, { createContext, useContext } from "react";
import { useEnvironment } from ".";

export const LocalStorageContext = createContext({
  environment: null,
});

const LocalStorageContextProvider = ({ children }) => {
  const {
    keys: { localStorage: localStorageKey },
  } = useEnvironment();
  const getAuthTokens = () => {
    const tokens = JSON.parse(localStorage.getItem(localStorageKey));
    if (!tokens) {
      return null;
    }

    return {
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
    };
  };

  const persistContent = (content) => {
    localStorage.setItem(localStorageKey, JSON.stringify(content));
  };

  const clean = () => {
    localStorage.removeItem(localStorageKey);
  };

  return (
    <LocalStorageContext.Provider
      value={{
        getAuthTokens,
        persistContent,
        clean,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = () => useContext(LocalStorageContext);

export default LocalStorageContextProvider;
