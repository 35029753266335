/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */

import { HttpError } from "ra-core";
import React, { createContext, useContext } from "react";
import { useApi, headers } from ".";
import { stringfyDate } from "./utils";

export const AdminDataContext = createContext({
  dataProvider: {
    getList: async () => {},
    getOne: async () => {},
    create: async () => {},
  },
});

const successResponseCodes = [
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
];

const convertFileToBase64 = (image) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(image.file);
  });

const AdminDataContextProvider = ({ children }) => {
  const { apiUrl, fetchApi } = useApi();

  const dataProvider = {
    getList: async (resource, params) => {
      let paginate = {
        page: params.pagination.page,
        per_page: params.pagination.perPage,
        search: params.filter.q || "",
      };

      if (params?.filter) {
        const filters = params.filter;
        paginate = { ...paginate, ...filters };
      }

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
           ? 'users'
           : resource; 

      const paginateUrl = new URLSearchParams(paginate).toString();
      const resourceFinal = endpoint.replaceAll('.','/');
      const url = `${apiUrl}/${resourceFinal}?${paginateUrl}`;
      const response = await fetchApi(url);
      const responseJson = await response.json();

      return {
        data: responseJson.data,
        total: responseJson.pagination.total_items,
      };
    },
    getMany: async (resource, params) => {
      // const paginate = {
      //   ids: params.ids || "",
      // };
      // const paginateUrl = new URLSearchParams(paginate).toString();

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
           ? 'users'
           : resource; 

      const resourceFinal = endpoint.replaceAll('.','/');
      const url = `${apiUrl}/${resourceFinal}/${params.ids}`;
      const response = await fetchApi(url);
      const responseJson = await response.json();

      return {
        data: [responseJson.data],
        total: responseJson.pagination.total_items,
      };
    },
    getOne: async (resource, { id }) => {

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
           ? 'users'
           : resource; 

      const resourceFinal = endpoint.replaceAll('.','/');
      const url = `${apiUrl}/${resourceFinal}/${id}`;
      const result = await fetchApi(url);
      const resultJson = await result.json();
      return { data: resultJson.data };
    },
    create: async (resource, { data: payload }) => {

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
      ? 'users'
      : resource; 

      try {
        const resourceFinal = endpoint.replaceAll('.','/');
        const url = `${apiUrl}/${resourceFinal}`;
        const result = await fetchApi(url, {
          method: "POST",
          headers: new Headers({
            [headers.ACCEPT]: "application/json",
            [headers.CONTENT_TYPE]: "application/json",
          }),
          body: JSON.stringify(stringfyDate(resourceFinal, payload)),
        });
        const result_json = await result.json();

        if (result.status < 200 || result.status >= 300) {
          return Promise.reject(new HttpError(result_json && result_json.msg));
        }

        return {
          data: result_json.data,
        };
      } catch (e) {
        console.log(e);
      }
    },
    update: async (resource, { id, data: payload }) => {

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
      ? 'users'
      : resource; 
      const resourceFinal = endpoint.replaceAll('.','/');

      try {
        const condition = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers' ? true : false
        if(condition){
          const url = `${apiUrl}/${resourceFinal}/${id}`;
          const response = await fetchApi(url, {
            method: "put",
            headers: new Headers({
              [headers.ACCEPT]: "application/json",
              [headers.CONTENT_TYPE]: "application/json",
            }),
            body: JSON.stringify({user_id: payload.id , email: payload.email, password: payload.password}),
          }); 
          const responseJson = await response.json();

          if (response.status >= 200 && response.status < 300) {
            return {
              data: response.data,
              total: responseJson.pagination ? responseJson.pagination.total_items : undefined,
            };
          } else {
            // Handle errors based on response status
            return Promise.reject(new HttpError(responseJson.msg, response.status, response));
          }
        } else {
          const url = `${apiUrl}/${resourceFinal}/${id}`;
          const response = await fetchApi(url, {
            method: "put",
            headers: new Headers({
              [headers.ACCEPT]: "application/json",
              [headers.CONTENT_TYPE]: "application/json",
            }),
            body: JSON.stringify(payload),
          });
          const response_json = await response.json();
          return {
            data: response_json.data,
            total: response_json.pagination.total_items,
          };
        }
      } catch (e) {
        console.log(e);
      }
    },
    delete: async (resource, { id }) => {
      try {

        const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
        ? 'users'
        : resource; 

        const resourceFinal = endpoint.replaceAll('.','/');
        const url = `${apiUrl}/${resourceFinal}/${id}`;
        const response = await fetchApi(url, {
          method: "delete",
        });
        if (response.ok) {
          return { data: response.data };
        } else {
          const responseResult = await response.json()
          return Promise.reject(new HttpError(responseResult.msg, responseResult.status, response));
        }
      } catch (e) {
        console.log(e);
      }
    },
    deleteMany: async (resource, params) => {

      const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
      ? 'users'
      : resource; 

      const paginate = {
        ids: params.ids || "",
      };
      const paginateUrl = new URLSearchParams(paginate).toString();
      const resourceFinal = endpoint.replaceAll('.','/');
      const url = `${apiUrl}/${resourceFinal}?${paginateUrl}`;
      await fetchApi(url, {
        method: "delete",
      });

      return {
        data: [],
      };
    },
    uploadImage: async (resource, { data: payload }) =>
      new Promise(async (resolve, reject) => {
        try {

          const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
          ? 'users'
          : resource; 

          const { idResource, images, type = "image" } = payload;
          const url = `${apiUrl}/${endpoint}/${idResource}/${type}`;
          const responseUpload = images.map(async (image, index) => {
            const infoImage = {
              order_position: index,
              color_hash: image.color_hash,
              featured: image.featured,
              link: image.link,
              route:
                image.route && image.reference
                  ? `${image.route}@${image.reference}`
                  : image.route,
              free: image.free,
              premium: image.premium,
            };

            if (image.type === "local") {
              const base64Picture = await convertFileToBase64(image);
              infoImage.image = base64Picture;
              return fetchApi(url, {
                method: "POST",
                headers: new Headers({
                  [headers.ACCEPT]: "application/json",
                  [headers.CONTENT_TYPE]: "application/json",
                }),
                body: JSON.stringify(infoImage),
              });
            }
            return fetchApi(`${url}/${image.id}`, {
              method: "PUT",
              headers: new Headers({
                [headers.ACCEPT]: "application/json",
                [headers.CONTENT_TYPE]: "application/json",
              }),
              body: JSON.stringify(infoImage),
            });
          });
          await Promise.all(responseUpload);
          resolve({
            data: { responseUpload },
            total: 0,
          });
        } catch (error) {
          reject(error);
        }
      }),
    deleteImage: async (resource, { data: payload }) =>
      new Promise(async (resolve, reject) => {

        const endpoint = resource === 'clients' || resource === 'brokerage_users' || resource === 'producers'
        ? 'users'
        : resource; 

        try {
          const { idResource, idImage, type = "image" } = payload;
          let url = `${apiUrl}/${endpoint}/${idResource}/${type}/${idImage}`;

          if (!idImage) {
            url = `${apiUrl}/${endpoint}/${idResource}/${type}`;
          }

          const response = await fetchApi(url, {
            method: "delete",
          });
          if (successResponseCodes.includes(String(response.status))) {
            resolve({
              data: { status: response.status },
              total: 0,
            });
          } else {
            throw response;
          }
        } catch (error) {
          reject(error);
        }
      }),
  };

  return (
    <AdminDataContext.Provider value={{ dataProvider }}>
      {children}
    </AdminDataContext.Provider>
  );
};

export const useAdminData = () => useContext(AdminDataContext);

export default AdminDataContextProvider;
