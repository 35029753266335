import { useState } from "react";
import { useApi } from "../context";

export const useDashboard = () => {
  const { apiUrl, fetchApi } = useApi();
  const [dashboard, setDashboard] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDashboard = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/dashboard`;
      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setDashboard(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.warn(err);
    }
  };

  return {
    dashboard,
    loading,

    // func
    getDashboard,
  };
};
