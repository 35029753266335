import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  SimpleList,
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
} from 'react-admin';
import { PageTitle } from '../../components';
import SearchList from '../../components/SearchList';


const BrandList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      filters={<SearchList />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ id }) => id}
          secondaryText={({ name }) => name}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" />
          <TextField label="Nome" source="name" />
          <ShowButton />
          <EditButton />
          <DeleteButton mutationMode="optimistic" />
        </Datagrid>
      )}
    </List>
  );
};

export default BrandList;
