import { useState } from "react";
import { useApi } from "../context";

export const useWallet = () => {
  const { apiUrl, fetchApi } = useApi();
  const [balance, setBalance] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [mainBankAccount, setMainBankAccount] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBalance = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/main/wallet/balance`;
      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setBalance(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.warn(err);
    }
  };

  const getTransfers = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/main/wallet/transfers`;
      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setTransfers(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.warn(err);
    }
  };

  const getMainAccount = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/main/wallet/banks?per_page=1`;
      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setMainBankAccount(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.warn(err);
    }
  };

  const createTransfer = async (bank_id, value) => {
    try {
      setLoading(true);
      const url = `${apiUrl}/main/wallet/transfers`;

      const formData = new FormData()
      formData.append('bank_id', bank_id)
      formData.append('value', value)

      const response = await fetchApi(url, {
        method: 'POST',
        body: JSON.stringify({
          bank_id,
          value
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        })
      });

      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setLoading(false);
      return responseJson

    } catch (err) {
      setLoading(false);
      console.warn(err);
      return err
    }
  };

  return {
    balance,
    loading,
    transfers,
    mainBankAccount,

    // get
    getBalance,
    getTransfers,
    getMainAccount,

    // create
    createTransfer,
  };
};
