import React, { useState } from 'react';
import { Create, TextInput, FormWithRedirect, BooleanInput, SelectInput, required, DateInput, minLength, maxLength, email } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import {
  CreatePageToolbar,
  PageTitle,
  ReturnActions,
} from '../../components';
import { mascaraCEP, mascaraCnpj, mascaraCpf, maskPhone } from '../../utils/maskInputs';
import { api } from '../../services/api';

const PartnerCreate = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(5, 'Insira pelo menos 6 caracteres')];
  const validatePhone = [required('Campo Obrigatório'), minLength(10), maxLength(16, 'Número inválido')];
  const validatePassword = [required('Campo Obrigatório'), minLength(7, 'Insira pelo menos 8 caracteres')];
  const validateGroup = required('Por favor, selecione o grupo');
  const validateEmail = [required('Campo Obrigatório'), email('Email inválido')];
  const [cep, setCep] = useState({});

  const getCep = async (cep)=> {
    if(cep.length > 8){
        const res = await api.get('v1/client/address/validate', {
          params: {
            code_post: cep
          }
        })
        setCep(res.data?.data);
      }
  }


return (
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>

          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar {props.options.singularLabel}
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput validate={validateName} label="Nome Fantasia" source="name" fullWidth />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Razão Social" source="social_name" validate={validateName} fullWidth />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <SelectInput source="type" label="Parceiro" validate={validateGroup} initialValue="state_partner" choices={[
                        { id: 'country_partner', name: 'Parceiro Nacional' },
                        { id: 'state_partner', name: 'Parceiro Estadual' },
                        // { id: 'company_partner', name: 'Empresa principal' },
                    ]} />
                  </Box>

                  <Box flex={1} mt="-2em" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Status
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput defaultValue={true} label="Ativo" source="status" fullWidth />
                    </Box>
                </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CNPJ"
                      source="ean"
                      validate={required('Campo obrigatório')}
                      fullWidth
                      format={mascaraCnpj}
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Telefone" validate={validatePhone}  format={maskPhone} parse={maskPhone} source="cell_phone" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Email" source="email"  validate={validateEmail} fullWidth />
                  </Box>
                </Box>
                
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" >
                  Registrar endereço do {props.options.singularLabel}
                </Typography>
                <Box display="flex">
                <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CEP"
                      format={mascaraCEP}
                      source="address.code_post"
                      fullWidth
                      onChange={e=> getCep(e.target.value)}
                      validate={required('Campo obrigatório')}
                    />
                  </Box>
                  
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput defaultValue={cep?.city?.state?.name} validate={required('Campo obrigatório')} disabled label="Estado" source="address.city.state.name" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                <Box flex={1} mr="0.5em" height="5em">
                    <TextInput defaultValue={cep?.city?.id} source="address.city.id" hidden style={{ display: 'none' }}/>
                    <TextInput defaultValue={cep?.city?.name} validate={required('Campo obrigatório')} disabled label="Cidade" source="address.city.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Bairro"
                      source="address.district"
                      fullWidth
                      defaultValue={cep?.district}
                      validate={required('Campo obrigatório')}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="Rua"
                      source="address.street"
                      defaultValue={cep?.street}
                      validate={required('Campo obrigatório')}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Número"
                      type="number"
                      source="address.number"
                      defaultValue={cep?.number}
                      validate={required('Campo obrigatório')}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Complemento"
                      validate={required('Campo obrigatório')}
                      source="address.complement"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar dados do responsável
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="Nome Completo"
                      source="user.name"
                      validate={validateName}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CPF"
                      format={mascaraCpf}
                      source="user.taxpayer"
                      validate={required('Campo obrigatório')}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="Telefone"
                      source="user.cell_phone"
                      format={maskPhone}
                      validate={validatePhone}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <DateInput                      label="Data de Nascimento"
                      source="user.birth_date"/>
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail do Responsável"
                      source="user.email"
                      validate={validateEmail}
                      type="email"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Senha"
                      source="user.password"
                      validate={validatePassword}
                      type="password"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
);
}

export default PartnerCreate;
