/* eslint-disable react/jsx-indent-props */
import * as React from 'react';
import { Box } from '@material-ui/core';
import { Edit, TextInput, FormWithRedirect, ReferenceInput, AutocompleteInput, SelectInput, required } from 'react-admin';

import { ReturnActions, EditPageToolbar, PageTitle } from '../../components';

const validateType = [required('Campo Obrigatório')];

const ModelsEdit = (props) => (
  <Edit
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="edit"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({
        basePath,
        handleSubmitWithRedirect,
        invalid,
        resource,
        saving,
        record,
      }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="0.5em" height="5em">
                <TextInput label="Nome" source="name" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box p="1em">
            <Box display="flex">
            <Box flex={1} height="auto">
              <ReferenceInput
                label="Marcas"
                reference="brands"
                source="car_brand_id"
              >
                <AutocompleteInput fullWidth />
              </ReferenceInput>
              <SelectInput
                label="Tipo de veiculo"
                source="vehicle_segment"
                choices={[
                  { id: 'all', name: 'Todos' },
                  { id: 'car' , name: 'Carro' },
                  { id: 'motorcycle ', name: 'Moto' },
                ]}
                validate={validateType}
                fullWidth
              />
            </Box>
            </Box>
          </Box>
          <EditPageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            basePath={basePath}
            saving={saving}
            record={record}
            resource={resource}
          />
        </form>
      )}
    />
  </Edit>
);

export default ModelsEdit;
