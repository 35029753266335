import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ModelsList from './ModelsList';
import ModelsCreate from './ModelsCreate';
import ModelsEdit from './ModelsEdit';
import ModelsShow from './ModelsShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "models",
  options: { label: 'Modelos', singularLabel: 'Modelo',  "menuParent": "settings" },
  list: ModelsList,
  edit: ModelsEdit,
  create: ModelsCreate,
  show: ModelsShow,
  icon: GroupWorkIcon,
};
