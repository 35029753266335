import BrokerIcon from '@material-ui/icons/Group';
import BrokerageUserCreate from './BrokerageUserCreate';
import BrokerageUserEdit from './BrokerageUserEdit';
import BrokerageUserList from './BrokerageUserList';


const BrokerageUserView = {
  name: 'brokerage_users',
  options: { label: 'Responsáveis', singularLabel: 'Responsável', "menuParent": "brokerages" },
  list: BrokerageUserList,
  edit: BrokerageUserEdit,
  create: BrokerageUserCreate,
  icon: BrokerIcon,
};

export default BrokerageUserView
