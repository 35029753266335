import SavingsIcon from '@mui/icons-material/Savings';
import Wallet from "./wallet";

const WalletView = {
    name: 'account',
    options: { label: 'Conta Digital', singularLabel: 'Conta Digital', "menuParent": "wallet" },
    list: Wallet,
    icon: SavingsIcon,
  };

export default WalletView;
