import * as React from 'react';
import { Box } from '@material-ui/core';
import { Create, TextInput, FormWithRedirect, SelectInput, required} from 'react-admin';

import { ReturnActions, CreatePageToolbar, PageTitle } from '../../components';


const BrandCreate = (props) => {
  const validateType = [required('Campo Obrigatório')];

  return(
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions {...props} />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="0.5em" height="5em">
                <TextInput label="Nome" source="name" fullWidth />
              </Box>
              <Box flex={2} mr="0.5em" height="5em">
                <SelectInput
                label="Tipo de veiculo"
                source="vehicle_segment"
                choices={[
                  { id: 'all', name: 'Todos' },
                  { id: 'car' , name: 'Carro' },
                  { id: 'motorcycle ', name: 'Moto' },
                ]}
                validate={validateType}
                fullWidth
              />
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
)};

export default BrandCreate;
