import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Edit, TextInput, SelectInput, FormWithRedirect } from 'react-admin';

import {
  ReturnActions,
  EditPageToolbar,
  PageTitle,
} from '../../components';

const InvoicesEdit = (props) => {
  const toPrice = (price) => {
    return price?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };
  
  return (
    <Edit
      title={
        <PageTitle
          label={props.options.singularLabel}
          type="edit"
          record={props.record}
        />
      }
      actions={<ReturnActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={({
          basePath,
          handleSubmitWithRedirect,
          invalid,
          resource,
          saving,
          record,
        }) => (
          <form>
            <Box p="1em">
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Alterar status da nota
                  </Typography>
                  <Box display="flex">
                    <TextInput format={toPrice} disabled label="Valor" source="value" />
                    <SelectInput
                      source="status"
                      label="Status da nota"
                      choices={[
                        { id: 'PENDING', name: 'Pendente' },
                        { id: 'APPROVED', name: 'Aprovada' },
                        { id: 'REJECTED', name: 'Rejeitada' },
                      ]}
                      style={{ marginLeft: '20px', marginRight: '20px' }}
                    />
                    
                    <TextInput fullWidth label="Descrição" source="description" />
                  </Box>
                  
                </Box>
              </Box>
            </Box>
            <EditPageToolbar
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              basePath={basePath}
              saving={saving}
              record={record}
              resource={resource}
            />
          </form>
        )}
      />
    </Edit>
  );
}

export default InvoicesEdit;
