import { useState } from "react";
import { useApi } from "../context";

export const useNegotiations = () => {
  const { apiUrl, fetchApi } = useApi();
  const [negotiations, setNegotiations] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNegotiations = async (filters = {}) => {
    try {
      setLoading(true);
      const url = (
        `${apiUrl}/negotiations?${new URLSearchParams(filters).toString()}`
      );
      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setNegotiations(responseJson.data);
      setSummaryData(responseJson.summary);
      setPagination(responseJson.pagination);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.warn(err);
    }
  };

  return {
    negotiations,
    summaryData,
    pagination,
    loading,

    // get
    getNegotiations,
  };
};
