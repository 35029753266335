import * as React from "react";
import { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from "react-admin";

const ListActions = (props) => {
  const {
    className,
    // exporter,
    filters,
    maxResults,
    isCreate,
    isExport,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  console.log("max", maxResults);
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {isCreate && <CreateButton basePath={basePath} />}
      {isExport && (
        <>
          {resource === "users" ? (
            <ExportButton
              disabled={total === 0}
              resource="users"
              sort={currentSort}
              filterValues={filterValues}
              label="Exportar users"
            />
          ) : (
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filterValues={filterValues}
              maxResults={maxResults}
            />
          )}
        </>
      )}
    </TopToolbar>
  );
};

export default ListActions;
