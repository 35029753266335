import React, { useState } from "react";
import { Typography, Box, InputAdornment } from "@material-ui/core";
import {
  Edit,
  TextInput,
  NumberInput,
  required,
  FormWithRedirect,
  // BooleanInput,
  minLength,
  maxLength,
  email,
} from "react-admin";
import InfoIcon from "@material-ui/icons/Info";
import { styled } from "@mui/system";
import { api } from "../../services/api";
import { mascaraCEP, mascaraCnpj, maskPhone } from "../../utils/maskInputs";

import { ReturnActions, EditPageToolbar, PageTitle } from "../../components";
import { Modal, Button } from "@mui/material";

const CompanyEdit = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(5, 'Insira pelo menos 6 caracteres')];
  const validatePhone = [required('Campo Obrigatório'), minLength(10), maxLength(16, 'Número inválido')];
  const validateEmail = [required('Campo Obrigatório'), email('Email inválido')];

  const [cep, setCep] = useState({});

  const [openI, setOpenI] = useState(false);
  const handleOpenI = () => setOpenI(true);
  const handleCloseI = () => setOpenI(false);

  const [openII, setOpenII] = useState(false);
  const handleOpenII = () => setOpenII(true);
  const handleCloseII = () => setOpenII(false);

  // const [openIII, setOpenIII] = useState(false);
  // const handleOpenIII = () => setOpenIII(true);
  // const handleCloseIII = () => setOpenIII(false);

  const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const Backdrop = styled("div")`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  const style = {
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 3,
    p: 2,
    px: 4,
    pb: 3,
  };

  const getCep = async (cep) => {
    if (cep.length > 8) {
      const res = await api.get("v1/client/address/validate", {
        params: {
          code_post: cep,
        },
      });
      setCep(res.data?.data);
    }
  };

  return (
    <Edit
      title={
        <PageTitle
          label={props.options.singularLabel}
          type="edit"
          record={props.record}
        />
      }
      actions={<ReturnActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={({
          basePath,
          handleSubmitWithRedirect,
          invalid,
          resource,
          saving,
          record,
        }) => (
          <form>
            <Box p="1em">
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Alterar {props.options.singularLabel} do Sistema Geral
                    <Button type="button" onClick={handleOpenI}>
                    <InfoIcon style={{ marginRight: 5 }} onClick={handleOpenI} />
                    Info
                  </Button>
                  <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={openI}
                    onClose={handleCloseI}
                    BackdropComponent={Backdrop}
                  >
                    <Box sx={style}>
                      <h2 id="unstyled-modal-title">Estas são as configurações gerais e financeira da pataforma</h2>
                      <p id="unstyled-modal-description">
                       Os dados abaixos serão utilizados tanto no site, quanto no cadastro da prataforma
                        Asaas, que faz todo gerenciamento financeiro, logo estes dados configuram a carteira.
                      </p>
                    </Box>
                  </StyledModal>
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput validate={validateName} label="Nome" source="name" fullWidth />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        validate={validateName}
                        label="Razão Social"
                        source="company_name"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                      required
                      label="CNPJ"
                      parse={mascaraCnpj}
                      format={mascaraCnpj}
                      source="ein"
                      fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput
                        validate={validatePhone}
                        format={maskPhone}
                        parse={maskPhone}
                        label="Telefone Fixo"
                        source="landline"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        validate={validatePhone}
                        format={maskPhone}
                        parse={maskPhone}
                        label="Telefone"
                        source="cell_phone"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        validate={validateEmail}
                        label="Email"
                        source="email"
                        type="email"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput label="Facebook" source="facebook" fullWidth />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        label="Instagram"
                        source="instagram"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput label="Versão IOS" source="ios_version" validate={validateName} fullWidth />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        label="Versão Android"
                        source="android_version"
                        validate={validateName}
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box flex={2} mr="1em">
                    <Typography variant="h6">
                      Endereço do Sistema
                      <Button type="button" onClick={handleOpenII}>
                    <InfoIcon style={{ marginRight: 5 }} onClick={handleOpenII} />
                    Info
                  </Button>
                  <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={openII}
                    onClose={handleCloseII}
                    BackdropComponent={Backdrop}
                  >
                    <Box sx={style}>
                      <h2 id="unstyled-modal-title">Este é o endereço definido como padrão da pataforma</h2>
                      <p id="unstyled-modal-description">
                       Os dados abaixos são utilizados no cadastro da Asaas (Agente financeiro)
                      </p>
                    </Box>
                  </StyledModal>
                    </Typography>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em" height="5em">
                        <TextInput
                          label="CEP"
                          format={mascaraCEP}
                          source="address.code_post"
                          fullWidth
                          onChange={(e) => getCep(e.target.value)}
                          validate={required("Campo obrigatório")}
                        />
                      </Box>

                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          defaultValue={cep?.city?.state?.name}
                          validate={required("Campo obrigatório")}
                          disabled
                          label="Estado"
                          source="address.city.state.name"
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em" height="5em">
                        <TextInput
                          defaultValue={cep?.city?.id}
                          source="address.city.id"
                          hidden
                          style={{ display: "none" }}
                        />
                        <TextInput
                          defaultValue={cep?.city?.name}
                          validate={required("Campo obrigatório")}
                          disabled
                          label="Cidade"
                          source="address.city.name"
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="Bairro"
                          source="address.district"
                          fullWidth
                          defaultValue={cep?.district}
                          validate={required("Campo obrigatório")}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em" height="5em">
                        <TextInput
                          label="Rua"
                          source="address.street"
                          defaultValue={cep?.street}
                          validate={required("Campo obrigatório")}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="Número"
                          type="number"
                          source="address.number"
                          defaultValue={cep?.number}
                          validate={required("Campo obrigatório")}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="Complemento"
                          source="address.complement"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box flex={1} ml="1em">
                  <Typography variant="h6" gutterBottom>
                    Comissões
                  </Typography>
                  <Box height="5em">
                    <NumberInput
                      source="commission_company"
                      label="Autoconect"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box height="5em">
                    <NumberInput
                      source="commission_country_partner"
                      label="Nacional"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box height="5em">
                    <NumberInput
                      required
                      source="commission_state_partner"
                      label="Estadual"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box height="5em">
                    <NumberInput
                      required
                      source="commission_advertiser"
                      label="Corretora do produtor anunciante"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box height="5em">
                    <NumberInput
                      required
                      source="commission_buyer"
                      label="Corretora do produtor comprador"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box height="5em">
                    <NumberInput
                      required
                      source="price_bill_registry"
                      label="Taxa do boleto"
                    />
                  </Box>
                </Box>
              </Box>

              {/* <hr  style={{  border: '1px dashed #F0781E', marginTop: '50px', marginBottom: '50px'}} /> */}
              
              {/* <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Configuração Asaas
                    <Button type="button" onClick={handleOpenIII}>
                      <InfoIcon style={{ marginRight: 5 }} onClick={handleOpenIII} />
                      Info
                    </Button>
                    <StyledModal
                      aria-labelledby="unstyled-modal-title"
                      aria-describedby="unstyled-modal-description"
                      open={openIII}
                      onClose={handleCloseIII}
                      BackdropComponent={Backdrop}
                    >
                      <Box sx={style}>
                        <h2 id="unstyled-modal-title">Este é o usuário definido como padrão da pataforma</h2>
                        <p id="unstyled-modal-description">
                        Os dados abaixos não tem vinculos com os dados acima, endereço e dados cadastrais
                        </p>
                        <p id="unstyled-modal-description">
                          estes dados são de uma agencia de corretora real designada a Autoconect
                        </p>
                      </Box>
                    </StyledModal>
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput disabled label="Asaas Client Id" source="asaas_client_id" fullWidth />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput disabled label="Asaas Wallet Id" source="asaas_wallet_id" fullWidth />
                    </Box>
                  </Box>
                  <Box flex={1} height="5em">
                    <TextInput disabled label="Asaas API Token" source="asaas_api_token" fullWidth />
                  </Box>
                </Box>
              </Box> */}

              {/* <hr  style={{  border: '1px dashed #F0781E', marginTop: '50px', marginBottom: '50px'}} /> */}

              {/* <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Configuração da Corretora Padrão da Autoconect
                  <Button type="button" onClick={handleOpenIII}>
                    <InfoIcon style={{ marginRight: 5 }} onClick={handleOpenIII} />
                    Info
                  </Button>
                  <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={openIII}
                    onClose={handleCloseIII}
                    BackdropComponent={Backdrop}
                  >
                    <Box sx={style}>
                      <h2 id="unstyled-modal-title">Este é o usuário definido como padrão da pataforma</h2>
                      <p id="unstyled-modal-description">
                       Os dados abaixos não tem vinculos com os dados acima, endereço e dados cadastrais
                      </p>
                      <p id="unstyled-modal-description">
                        estes dados são de uma agencia de corretora real designada a Autoconect
                      </p>
                    </Box>
                  </StyledModal>
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput  label="Nome" source="brokerage_agency.name" fullWidth />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput  label="Telefone" source="brokerage_agency.cell_phone" fullWidth />
                    </Box>
                    <Box flex={1} mt="-2em" ml="1em">
                      <Typography variant="h6" gutterBottom>
                        Status 
                      </Typography>
                      <Box height="5em" display="flex" alignItems="center">
                        <BooleanInput disabled label="Ativo" source="brokerage_agency.status" fullWidth />
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput
                        label="CNPJ"
                        required
                        parse={mascaraCnpj}
                        format={mascaraCnpj}
                        source="brokerage_agency.ean"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        label="Susep"
                        source="brokerage_agency.susep"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput
                        validate={validateEmail}
                        label="E-mail"
                        source="brokerage_agency.email"
                        type="email"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em" height="5em">
                      <TextInput
                        label="Senha"
                        disabled
                        defaultValue={'123456789'}
                        // source="brokerage_agency.password"
                        type="password"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
              </Box> */}

            {/* <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Endereço Corretora Autoconect
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput required  label="Cidade" source="brokerage_agency.address.city.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput required  label="Estado" source="brokerage_agency.address.city.state.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput required label="UF" source="brokerage_agency.address.city.state.uf" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CEP"
                      required
                      source="brokerage_agency.address.code_post"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Bairro"
                      required
                      source="brokerage_agency.address.district"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      required
                      label="Rua"
                      source="brokerage_agency.address.street"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      required
                      label="Número"
                      type="number"
                      source="brokerage_agency.address.number"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      required
                      label="Complemento"
                      source="brokerage_agency.address.complement"
                      fullWidth
                    />
                  </Box>
                </Box>
                </Box>
              </Box>*/}
            </Box> 
            <EditPageToolbar
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              basePath={basePath}
              saving={saving}
              record={record}
              resource={resource}
              noDelete
            />
          </form>
        )}
      />
    </Edit>
  );
};

export default CompanyEdit;
