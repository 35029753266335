import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  SimpleList,
  List,
  Datagrid,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  SearchInput,
  downloadCSV,
  // DateInput,
  FunctionField,
  DateField,
} from 'react-admin';
import { PageTitle, ListActions, EmptyData } from '../../components';
import jsonExport from 'jsonexport/dist';
import { maskPhone, statusPartner } from '../../utils/maskInputs';

const FilterProducer = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="status"
      label="Status do produtor"
      choices={[
        { id: null, name: 'Todos' },
        { id: '1', name: 'Ativos' },
        { id: '0', name: 'Inativos' },
      ]}
    />
    {/* <DateInput source="created_at_after" label="Cadastrado desde" />
    <DateInput source="created_at_before" label="Cadastrado antes" /> */}
  </Filter>
);
const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      cell_phone,
      status,
      cpf,
      social_media,
      config,
      image,
      group,
      hash_id,
      ...postForExport
    } = user;
    return postForExport;
  });

  jsonExport(
    usersForExport,
    {
      headers: ['id', 'name', 'email', 'created_at'],
    },
    (err, csv) => {
      downloadCSV(csv, 'users');
    }
  );
};

const ProducerList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterProducer />}
      filterDefaultValues={{ in_group: [4] }}
      actions={<ListActions />}
      empty={<EmptyData text="Não existem produtores cadastrados" />}
      exporter={exporter}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ email }) => email}
          tertiaryText={({ cell_phone }) => cell_phone}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" emptyText="Não informado" />
          <TextField label="Nome" source="name" emptyText="Não informado" />
          <TextField label="Corretora" source="partner.name" emptyText="Não informado" />
          <FunctionField
            label="Status" 
            render={record => statusPartner(record.status)}
              source="status" emptyText="Não informado"
          />
          <TextField label="Grupo" source="group.name" emptyText="Não informado" />
          <FunctionField
            label="Telefone" 
            render={record =>`${maskPhone(record?.cell_phone)}`}
              source="cell_phone" emptyText="Não informado"
          />
          <EmailField label="e-mail" source="email" emptyText="Não informado" />
          <DateField label="Data entrada" source="created_at" emptyText="Não informado" />
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default ProducerList;
