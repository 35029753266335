import {HomeWork} from '@material-ui/icons';
import PartnerList from './PartnerList';
import PartnerCreate from './PartnerCreate';
import PartnerEdit from './PartnerEdit';

const PartnerView = {
  name: 'partners',
  options: { label: 'Parceiros', singularLabel: 'Parceiro' },
  list: PartnerList,
  edit: PartnerEdit,
  create: PartnerCreate,
  icon: HomeWork,
};

export default PartnerView
