import { Person } from "@material-ui/icons";
import ClientsList from './ClientsList';
import ClientsEdit from './ClientsEdit';

const ClientsView = {
  name: 'clients',
  options: { label: 'Clientes', singularLabel: 'Clientes' },
  list: ClientsList,
  edit: ClientsEdit,
  // show:
  icon: Person,
};

export default ClientsView
