import React from "react";
import { Typography, Box } from "@material-ui/core";
import {
  Edit,
  TextInput,
  ImageField,
  BooleanInput,
  FormWithRedirect,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
} from "react-admin";

import { ReturnActions, EditPageToolbar, PageTitle } from "../../components";
import { maskPhone } from "../../utils/maskInputs";

const UserEdit = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(6), maxLength(20)];
  const validatePhone = [required('Campo Obrigatório'), minLength(10), maxLength(16, 'Número inválido')];
  const validateGroup = required('Por favor, selecione o grupo');
  const validateEmail = [required('Campo Obrigatório'), email('Email inválido')];
return(
  <Edit
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="edit"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({
        basePath,
        handleSubmitWithRedirect,
        invalid,
        resource,
        saving,
        record,
      }) => (
        <form>
          {console.log(record)}
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Alterar Administrador
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Nome" validate={validateName} source="name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Telefone" validate={validatePhone}  format={maskPhone} parse={maskPhone} source="cell_phone" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail"
                      source="email"
                      validate={validateEmail}
                      type="email"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Senha"
                      source="password"
                      type="password"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>

              <Box flex={1} ml="1em">
                <Typography variant="h6" gutterBottom>
                  Status
                </Typography>
                <Box height="5em" display="flex" alignItems="center">
                  <BooleanInput label="Ativo" source="status" fullWidth />
                </Box>
                <Box height="5em">
                  <SelectInput
                    source="group_id"
                    label="Grupo"
                    validate={validateGroup}
                    choices={[{ id: 2, name: "Administrador" }]}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <ImageField
                disabled
                label="Imagens"
                source="image.medium"
                type="text"
                emptyText="Sem imagens cadastradas"
                fullWidth
              />
            </Box>
          </Box>
          <EditPageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            basePath={basePath}
            saving={saving}
            record={record}
            resource={resource}
          />
        </form>
      )}
    />
  </Edit>
)
}

export default UserEdit;
