/* eslint-disable react/prop-types */
import React, { createContext, useContext } from "react";

export const EnvironmentContext = createContext({
  api: {},
  tokens: {},
  keys: {},
});

const EnvironmentContextProvider = ({ children }) => {
  const {
    REACT_APP_API_HOST,
    REACT_APP_API_PORT,
    REACT_APP_API_VERSION,
    REACT_APP_API_PATH,
    REACT_APP_LOCAL_STORAGE_KEY,
    REACT_APP_ADMIN_TOKEN,
  } = process.env;

  const environment = {
    api: {
      host: REACT_APP_API_HOST,
      port: REACT_APP_API_PORT,
      version: REACT_APP_API_VERSION,
      path: REACT_APP_API_PATH,
    },
    tokens: {
      admin: REACT_APP_ADMIN_TOKEN,
    },
    keys: {
      localStorage: REACT_APP_LOCAL_STORAGE_KEY,
    },
  };

  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => useContext(EnvironmentContext);

export default EnvironmentContextProvider;
