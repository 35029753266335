import {HomeWork} from '@material-ui/icons';
import BrokerList from './BrokerList';
import BrokerCreate from './BrokerCreate';
import BrokerEdit from './BrokerEdit';

const BrokerView = {
  name: 'brokerage_agencies',
  options: { label: 'Listar Corretoras', singularLabel: 'Corretora',  "menuParent": "brokerages" },
  list: BrokerList,
  edit: BrokerEdit,
  create: BrokerCreate,
  icon: HomeWork,
};

export default BrokerView
