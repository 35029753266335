import React from "react";
import { Create, TextInput, FormWithRedirect, BooleanInput } from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { CreatePageToolbar, PageTitle, ReturnActions } from "../../components";

const AdsCreate = (props) => (
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar Anúncio
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Nome" source="name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Telefone" source="cell_phone" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail"
                      source="email"
                      type="email"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Senha"
                      source="password"
                      type="password"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>

              <Box flex={1} ml="1em">
                <Typography variant="h6" gutterBottom>
                  Status
                </Typography>
                <Box height="5em" display="flex" alignItems="center">
                  <BooleanInput label="Ativo" source="status" fullWidth />
                </Box>
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
);

export default AdsCreate;
