import SecurityIcon from '@mui/icons-material/Security';
import InsuranceList from './InsuranceList';
import InsuranceCreate from './InsuranceCreate';
import InsuranceEdit from './InsuranceEdit';

const InsuranceView = {
  name: 'insurance_companies',
  options: { label: 'Seguradoras', singularLabel: 'Seguradoras',  "menuParent": "settings" },
  list: InsuranceList,
  edit: InsuranceEdit,
  create: InsuranceCreate,
  icon: SecurityIcon,
};

export default InsuranceView
