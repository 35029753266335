import SettingsIcon from '@mui/icons-material/Settings';
import CompanyEdit from "./CompanyEdit";
import CompanyList from "./CompanyList";

const CompanyView = {
  name: "main",
  options: { label: "Gerais", singularLabel: "Geral",  "menuParent": "settings" },
  edit: CompanyEdit,
  list: CompanyList,
  icon: SettingsIcon,
};

export default CompanyView;
