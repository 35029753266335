import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  SimpleList,
  List,
  Datagrid,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  SearchInput,
  downloadCSV,
  // DateInput,
  DateField,
  FunctionField,
} from 'react-admin';
import { PageTitle, ListActions, EmptyData } from '../../components';
import jsonExport from 'jsonexport/dist';
import { statusBroker } from '../../utils/maskInputs';

const FilterBroker = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      style={{width: 300}}
      source="status"
      label="Status da corretora"
      choices={[
        { id: 'all', name: 'Todos' },
        { id: 'true', name: 'Ativa' },
        { id: 'false', name: 'Inativa' },
      ]}
    />
    {/* <DateInput source="created_at_after" label="Cadastrado desde" />
    <DateInput source="created_at_before" label="Cadastrado antes" /> */}
  </Filter>
);
const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      cell_phone,
      status,
      cpf,
      social_media,
      config,
      image,
      group,
      hash_id,
      ...postForExport
    } = user;
    return postForExport;
  });

  jsonExport(
    usersForExport,
    {
      headers: ['id', 'name', 'email', 'created_at'],
    },
    (err, csv) => {
      downloadCSV(csv, 'users');
    }
  );
};

const BrokerList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
    data-cy="listCorretoras"
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterBroker />}
      actions={<ListActions />}
      exporter={exporter}
      empty={<EmptyData text="Não existem corretoras cadastradas" />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ email }) => email}
          tertiaryText={({ cell_phone }) => cell_phone}
        />
      ) : (
        <Datagrid data-cy="datagridCorretoras">
          <TextField label="ID" source="id" emptyText="Não informado"/>
          <TextField label="Nome" source="name" emptyText="Não informado"/>
          <FunctionField
            label="Status" 
            render={record => statusBroker(record.status)}
              source="status" emptyText="Não informado"
          />
          <TextField label="Cnpj" source="ean" emptyText="Não informado"/>
          <TextField label="Telefone" source="cell_phone" emptyText="Não informado"/>
          <EmailField label="e-mail" source="email" emptyText="Não informado"/>
          <TextField label="Estado" source="address.city.state.name" emptyText="Não informado"/>
          <TextField label="Cidade" source="address.city.name" emptyText="Não informado"/>
          <DateField label="Data entrada" source="created_at" emptyText="Não informado"/>
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default BrokerList;
