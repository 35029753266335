import { api } from "../services/api";

export const useUser = () => {
  const sendPasswordRecovery = async ({ email }: { email: string }) => {
    await api.post("v1/admin/users/recover", {
      email,
    });
  };

  return { sendPasswordRecovery };
};
