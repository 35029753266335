import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { useApi, useAdminData } from '../context';
import { createBrowserHistory as createHistory } from 'history';
import TreeMenu from '@bb-tech/ra-treemenu';
import { TableChart } from "@material-ui/icons";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import i18nProvider from './language';
import { createTheme, ThemeProvider } from '@mui/material';
// Views
import {
  dashboard,
  user,
  negotiations,
  transfers_report,
  ads,
  partner,
  brokerage_agencies,
  brokerage_users,
  producer,
  company,
  insurance_companies,
  wallet,
  banks,
  invoices,
  clients,
  settings_brands,
  settings_models,
  settings_colors,
} from '../views';
import { MyLoginPage } from "../views/login/login";
import MyLayout from "../components/MyLayout";
import RecoverPasswordPage from '../views/recovery_password';

// var currentUser = jwt(localStorage.getItem("token"));

const theme = createTheme({
  palette: {
    background: {default: '#F5F5F5', },
    secondary: {main: '#F0781E'},
    primary: {main: '#F0781E'},
  },
});
const history = createHistory();
const AdminPanel = () => {
  const { adminAuthProvider } = useApi();
  const { dataProvider } = useAdminData();

  return (
    <ThemeProvider theme={theme}>
      <Admin
        history={history}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={adminAuthProvider}
        dashboard={dashboard}
        theme={theme}
        loginPage={MyLoginPage}
        layout={(props) => <MyLayout {...props} menu={TreeMenu} />}
        customRoutes={[
          <Route
              key="my-profile"
              path="/my-profile"
              component={user}
          />,
          <Route
            exact
            path="/forgot_password"
            component={RecoverPasswordPage}
            {...{ noLayout: true }}
          />,
        ]}
      >
        <Resource {...ads} />
        <Resource {...invoices} />
        <Resource 
          name="wallet" 
          icon={AccountBalanceWalletIcon} 
          options={{ "label": "Carteira", "isMenuParent": true }} />
        <Resource {...wallet} />
        <Resource {...banks} />
        <Resource {...partner} />
        <Resource 
          name="brokerages" 
          icon={TableChart} 
          options={{ "label": "Corretoras", "isMenuParent": true }} 
        />
        <Resource {...brokerage_agencies} />
        <Resource {...brokerage_users} />
        <Resource {...producer} />
        <Resource {...clients} />
        <Resource 
          name="reports" 
          icon={TableChart} 
          options={{ "label": "Relatórios", "isMenuParent": true }} 
        />
        <Resource {...negotiations} />
        <Resource {...transfers_report} />
        <Resource 
          name="settings" 
          icon={TableChart} 
          options={{ "label": "Configurações", "isMenuParent": true }} 
        />
        <Resource {...company} />
        <Resource {...insurance_companies} />
        <Resource {...settings_brands} />
        <Resource {...settings_models} />
        <Resource {...settings_colors} />
        <Resource {...user} />
      </Admin>
    </ThemeProvider>
  );
};

export default AdminPanel;
