import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UserList from "./UserList";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";

const UserView = {
  name: "users",
  options: { label: "Administradores", singularLabel: "Adiministrador(a)",  "menuParent": "settings" },
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  icon: AdminPanelSettingsIcon,
};

export default UserView;
