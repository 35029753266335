import * as React from 'react';
import { Box } from '@material-ui/core';
import { Create, TextInput, FormWithRedirect } from 'react-admin';
import { ColorInput } from 'react-admin-color-input';

import { ReturnActions, CreatePageToolbar, PageTitle } from '../../components';

const ColorCreate = (props) => (
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions {...props} />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="0.5em" height="5em">
                <TextInput label="Nome" source="name" fullWidth />
              </Box>
              <Box
                flex={2}
                mr="0.5em"
                height="5em"
                style={{ marginTop: '-8px' }}
              >
                <ColorInput label="Cor" source="color_hash" fullWidth />
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
);

export default ColorCreate;
