import React from "react";
import {
  Create,
  TextInput,
  FormWithRedirect,
  BooleanInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { CreatePageToolbar, PageTitle, ReturnActions } from "../../components";
import { maskPhone } from "../../utils/maskInputs";


const UserCreate = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(5, 'Insira pelo menos 8 caracteres'), maxLength(20, 'Máximo de 20 caracteres')];
  const validatePhone = [required('Campo Obrigatório'), minLength(10), maxLength(16, 'Número inválido')];
  const validatePassword = [required('Campo Obrigatório'), minLength(7, 'Insira pelo menos 8 caracteres')];
  const validateGroup = required('Por favor, selecione o grupo');
  const validateEmail = [required('Campo Obrigatório'), email('Email inválido')];
  return(
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar administrador
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Nome" source="name" validate={validateName} fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Telefone" validate={validatePhone} format={maskPhone} parse={maskPhone} source="cell_phone" fullWidth />
                  </Box>
                  <Box flex={1} mt="-2rem" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Status
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput
                        defaultValue={true}
                        label="Ativo"
                        source="status"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail"
                      source="email"
                      type="email"
                      validate={validateEmail}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Senha"
                      source="password"
                      validate={validatePassword}
                      type="password"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <SelectInput
                      source="group_id"
                      label="Grupo"
                      validate={validateGroup}
                      aria-required
                      choices={[{ id: 2, name: "Administrador" }]}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
)
};

export default UserCreate;
