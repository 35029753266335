import React from "react";
/* eslint-disable no-unused-vars */
interface PageTitlePropsRecord {
  id: number;
  name: string;
}

enum PageTitleTypeEnum {
  list = "list",
  show = "show",
  edit = "edit",
  create = "create",
}

interface PageTitleProps {
  label: string;
  type: PageTitleTypeEnum;
  record: PageTitlePropsRecord;
}

const PageTitle = ({ label, type, record }: PageTitleProps) => {
  switch (type) {
    case PageTitleTypeEnum.show:
      return <span style={{ color: "#fff" }}>{`${label} #${record.id}`}</span>;
    case PageTitleTypeEnum.edit:
      return (
        <span style={{ color: "#fff" }}>{`Editar ${label} #${record.id}`}</span>
      );
    case PageTitleTypeEnum.create:
      return <span style={{ color: "#fff" }}>{`Criar ${label}`}</span>;
    default:
      return <span style={{ color: "#fff" }}>{label}</span>;
  }
};

export default PageTitle;
