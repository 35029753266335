import React from 'react';
import { Create, TextInput, FormWithRedirect, BooleanInput, FunctionField } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import {
  CreatePageToolbar,
  PageTitle,
  ReturnActions,
} from '../../components';
import { maskPhone } from '../../utils/maskInputs';

const BrokerCreate = (props) => {
  const type = 'broker_partner'
const typeAccount = val => {
  return val ==='broker_partner' && 'Produtor'
}


return (
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>
                    <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar corretora
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Nome" source="name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Telefone" format={maskPhone} source="cell_phone" fullWidth />
                    <FunctionField
                        label="Telefone" 
                        render={record =>`${maskPhone(record?.cell_phone)}`}
                          source="cell_phone" emptyText="Não informado"
                      />
                  </Box>
                  <Box flex={1} mt="-2em" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Status
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput defaultValue={true} label="Ativo" source="status" fullWidth />
                    </Box>
                </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CNPJ"
                      source="ean"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Susep"
                      source="susep"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Grupo de usuário"
                      source="type"
                      defaultValue={type}
                      format={typeAccount}
                      disabled
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar endereço da corretora
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput label="Cidade" source="address.city.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="Estado" source="address.city.state.name" fullWidth />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput label="UF" source="address.city.state.uf" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CEP"
                      source="address.code_post"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Bairro"
                      source="address.district"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="Rua"
                      source="address.street"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Número"
                      type="number"
                      source="address.number"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Complemento"
                      source="address.complement"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar dados de acesso
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="E-mail"
                      source="email"
                      type="email"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      label="Senha"
                      source="password"
                      type="password"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
);
}

export default BrokerCreate;
