import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  SimpleList,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  FunctionField,
} from 'react-admin';
import { PageTitle, ListActions } from '../../components';
import { mainBankAccount } from '../../utils/maskInputs';

const BanksList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      actions={<ListActions isCreate={ props.hasCreate } />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ status }) => status}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" emptyText="Não informado"/>
          <TextField label="Titular" source="owner_name" emptyText="Não informado"/>
          <TextField label="CPF/CNPJ do titular" source="owner_taxpayer_ein" emptyText="Não informado"/>
          <TextField label="Banco" source="bank.name" emptyText="Não informado"/>
          <TextField label="Agência" source="agency" emptyText="Não informado"/>
          <TextField label="Conta" source="account" emptyText="Não informado"/>
          <TextField label="Dígito" source="account_digit" emptyText="Não informado"/>
          <FunctionField
            label="Conta principal" 
            render={record => mainBankAccount(record.main_account)}
              source="status" emptyText="Não informado"
          />
          {/* <TextField label="CNPJ" source="ean" emptyText="Não informado"/>

          <DateField label="Data entrada" source="created_at" emptyText="Não informado"/> */}
          <DeleteButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default BanksList;
