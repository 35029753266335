import Chip from '@mui/material/Chip';

export const maskPhone =(v) => {
  if (v !== null) {
    v=v?.replace(/\D/g,"");            
    v=v?.replace(/^(\d{2})(\d)/g,"($1) $2"); 
    v=v?.replace(/(\d)(\d{4})$/,"$1-$2");   
    return v;
  } else {
    return 'Não informado';
  }
}


export function mascaraCpf(v) {
  if (v !== null) {
    v = v?.replace(/\D/g,"");
    const x = v?.replace(/\D/g, '')?.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    if(x){
      v = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '-' + x[4];
      return v;
    }
  }
}


export function mascaraCEP(v) {
  if (v !== null) {
    v = v?.replace(/\D/g,"");
    const x = v?.replace(/\D/g, '')?.match(/(\d{0,5})(\d{0,3})/);
    if(x){
      v = !x[2] ? x[1] : x[1] + '-' + x[2];
      return v;
    }
  }
}

export function mascaraData(v) {
  if (v !== null) {
    v = v?.replace(/\D/g,"");
    const x = v?.replace(/\D/g, '')?.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    if(x){
      v = !x[2] ? x[1] : x[1] + '/' + x[2] + '/' + x[3];
      return v;
    }
  }
}

export function mascaraCnpj(v) {
  if (v !== null) {
    const x =  v?.replace(/\D/g, '')?.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    if(x){
      v = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
      return v;
    }
  }
}

export function statusBroker(v) {
if (v === true) {
  return <span style={{color: 'white', padding: 3, background: 'green', borderRadius: 5, fontSize: 10}}>Ativa</span>;
 }
if (v === false) {
  return <span style={{color: 'white', padding: 3, background: 'red', borderRadius: 5, fontSize: 10}}>Inativa</span>;
 }
}

export function statusPartner(v) {
if (v === true) {
  return <span style={{color: 'white', padding: 3, background: 'green', borderRadius: 5, fontSize: 10}}>Ativo</span>;
 }
if (v === false) {
  return <span style={{color: 'white', padding: 3, background: 'red', borderRadius: 5, fontSize: 10}}>Inativo</span>;
 }
}

export function statusInsuranceCompanies(v) {
  if (v === true) {
    return <span style={{color: 'white', padding: 3, background: 'green', borderRadius: 5, fontSize: 10}}>Ativo</span>;
  }
  if (v === false) {
    return <span style={{color: 'white', padding: 3, background: 'red', borderRadius: 5, fontSize: 10}}>Inativo</span>;
  }
}

export function mainBankAccount(v) {
  if (v === true) {
    return <span style={{color: 'white', padding: 3, background: 'green', borderRadius: 5, fontSize: 10}}>Ativo</span>;
  }
  if (v === false) {
    return <span style={{color: 'white', padding: 3, background: 'red', borderRadius: 5, fontSize: 10}}>Inativo</span>;
  }
}

export const toCurrency = (price) => {
  let valor = Math.round(price * 100) + ''
  valor = parseInt(valor.replace(/[\D]+/g, ''))
  valor = valor + ''
  valor = valor.replace(/([0-9]{2})$/g, ',$1')
  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
  }
  if (price < 0) {
    return `- R$ ${valor}`
  }
  return `R$ ${valor}`
}

export const statusAsaas = (status) => {
  const statusSettings = {
    PENDING: {
      label: 'Pendente',
      color: '#D3C333',
    },
    FAILED: {
      label: 'Recusado',
      color: '#EB0000',
    },
    CANCELLED: {
      label: 'Cancelado',
      color: '#B22222',
    },
    REJECTED: {
      label: 'Rejeitado',
      color: '#B22222',
    },
    OVERDUE: {
      label: 'Vencido',
      color: '#696969',
    },
    DONE: {
      label: 'Aprovado',
      color: '#0A9330',
    },
    APPROVED: {
      label: 'Aprovado',
      color: '#0A9330',
    },
    SUCCESS: {
      label: 'Aprovado',
      color: '#0A9330',
    },
    RECEIVED: {
      label: 'Recebido',
      color: '#0A9330',
    },
    CONFIRMED: {
      label: 'Confirmado',
      color: '#4682B4',
    },
    BANK_PROCESSING: {
      label: 'Processando',
      color: '#1569A8',
    },
  }

  return (
    <Chip
      sx={{
        background: statusSettings[status]?.color,
        color: '#FFF',
        fontWeight: 'bold',
        width: '120px',
        
      }}
      label={statusSettings[status]?.label}
    />
  )
}

export const statusPayment = (status) => {
  const statusSettings = {
    WAITING_FOR_BILL_GENERATION: {
      label: 'Em negociação',
      color: '#1569A8',
    },
    IN_PROGRESS: {
      label: 'Aguardando pagamento',
      color: '#D3C333',
    },
    DONE: {
      label: 'Concluída',
      color: '#0A9330',
    },
    CANCELLED: {
      label: 'Cancelada',
      color: '#EB0000',
    },
  }

  return (
    <Chip
      sx={{
        background: statusSettings[status]?.color ?? '#1569A8',
        color: '#FFF',
        fontWeight: 'bold',
        width: '100%',
      }}
      label={statusSettings[status]?.label ?? 'Em negociação'}
    />
  )
}

