import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BanksList from "./BanksList";
import BanksCreate from "./BanksCreate";
import BanksEdit from "./BanksEdit";

const BanksView = {
  name: 'main.wallet.banks',
  options: { label: 'Bancos', singularLabel: 'Bancos', "menuParent": "wallet" },
  list: BanksList,
  edit: BanksEdit,
  create: BanksCreate,
  icon: AccountBalanceIcon,
};

export default BanksView
