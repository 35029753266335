import "./App.css";
import {
  AdminDataContextProvider,
  ApiContextProvider,
  EnvironmentContextProvider,
  LocalStorageContextProvider,
} from "./context";
import AdminPanel from "./core";

function App() {
  return (
    <EnvironmentContextProvider>
      <LocalStorageContextProvider>
        <ApiContextProvider>
          <AdminDataContextProvider>
            <AdminPanel />
          </AdminDataContextProvider>
        </ApiContextProvider>
      </LocalStorageContextProvider>
    </EnvironmentContextProvider>
  );
}

export default App;
