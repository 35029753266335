import React from 'react';
import { 
  Create, 
  TextInput, 
  FormWithRedirect, 
  BooleanInput, 
  required, 
  minLength, 
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import {
  CreatePageToolbar,
  PageTitle,
  ReturnActions,
} from '../../components';

const InsuranceCreate = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(5, 'Insira pelo menos 6 caracteres')];


return (
  <Create
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="create"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
        <form>

          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Registrar {props.options.singularLabel}
                </Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput validate={validateName} label="Nome" source="name" fullWidth />
                  </Box>

                  <Box flex={1} mt="-2em" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Status
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput defaultValue={true} label="Ativo" source="status" fullWidth />
                    </Box>
                </Box>
                </Box>
                {/* <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      label="CNPJ"
                      source="ean"
                      validate={required('Campo obrigatório')}
                      fullWidth
                      format={mascaraCnpj}
                    />
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <CreatePageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            basePath={basePath}
          />
        </form>
      )}
    />
  </Create>
);
}

export default InsuranceCreate;
