/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNegotiations } from "../../hooks/useNegotiations"
import {
  Datagrid,
  TextField,
  SelectInput,
  ListContextProvider,
  TextInput,
  DateInput,
  SimpleForm,
  Pagination,
  FunctionField,
} from 'react-admin';
import keyBy from 'lodash/keyBy'
import Moment from 'moment'
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { statusPayment } from "../../utils/maskInputs";
import { toCurrency } from "../../utils/maskInputs";

const ValueCard = ({value, name, loading, Icon, iconColor, ...rest}) => (
  <Box
    style={{
      background: 'white',
      padding: '20px 30px',
      width: '150px',
      height: '90px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      flex: 1,
    }}
    {...rest}
  >
    <div
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        height: '100%'
      }}
    >
      <Icon 
        sx={{
          fontSize: 40,
          color: iconColor,
        }}
        inheritViewBox
      />
    </div>
    <div 
      style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'end', 
        justifyContent: 'space-between',
        textAlign: 'right',
        height: '100%'
      }}
    >
      <Typography 
        sx={{
          fontSize: '20px', 
          fontWeight: 'bold',
        }}
      >
        {name}
      </Typography>
      
      <Typography 
        sx={{
          fontSize: '28px',
        }}
      >
        {!value?.toString() || loading ? (<Skeleton />) : value}
      </Typography>
    </div>
  </Box>
);

const Spacer = () => <span style={{ width: "1em" }} />;

const initialFilters = {
  search: '',
  status: '',
  date_start: Moment().startOf('month').format('yyyy-MM-DD'),
  date_end: Moment().endOf('month').format('yyyy-MM-DD'),
  page: 1,
  per_page: 10,
}

const ReportList = () => {
  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const {
    negotiations,
    summaryData,
    pagination,
    loading,
    getNegotiations,
  } = useNegotiations()

  useEffect(() => {
    getNegotiations({...filters, page, per_page: perPage});
  }, [filters, page, perPage])


  const toCalc = (percent, totalPrice) => {
    const calc = (percent * totalPrice) / 100 
    return calc?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <div style={{marginTop: '20px'}}>
      <div style={{display: 'flex'}} data-cy="divCards">
        <ValueCard 
          value={summaryData.WAITING_FOR_BILL_GENERATION} 
          name="Negociações em andamento" 
          Icon={AccessTimeIcon} 
          iconColor="#1569A8"
          loading={loading}
        />
        <Spacer />
        <ValueCard 
          value={summaryData.IN_PROGRESS} 
          name="Negociações pendentes" 
          Icon={PendingActionsIcon} 
          iconColor="#D3C333" 
          loading={loading} 
        />
        <Spacer />
        <ValueCard 
          value={summaryData.DONE} 
          name="Negociações concluídas" 
          Icon={CheckCircleOutlineIcon} 
          iconColor="#0A9330" 
          loading={loading} 
        />
        <Spacer />
        <ValueCard 
          value={summaryData.CANCELLED} 
          name="Negociações canceladas" 
          Icon={ErrorOutlineIcon} 
          iconColor="#EB0000" 
          loading={loading} 
        />
        <Spacer />
        <ValueCard 
          value={summaryData.COMMISSION} 
          name="Comissões" 
          Icon={AttachMoneyIcon} 
          iconColor="#F0781E" 
          loading={loading} 
        />
        <Spacer />
      </div>
      <div>
        <Typography 
          sx={{
            color: '#505255', 
            fontSize: '18px', 
            fontWeight: 'bold',
            marginTop: '50px'
          }}
        >
          Filtros
        </Typography>
        <SimpleForm toolbar={false} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', width: '100%' }} >
            <TextInput 
              onChange={(event) => setFilters(old => ({...old, search: event.target?.value}))}
              variant="outlined" 
              style={{ marginRight: '20px' }} 
              source="search" 
              label="Busque por anúncio" 
            />
            <SelectInput 
              onChange={(event) => setFilters(old => ({...old, status: event?.target?.value ?? ''}))}
              variant="outlined" 
              style={{ marginRight: '20px' }}
              resettable
              optionValue="id"
              optionText="label"
              choices={[
                { id: 'WAITING_FOR_BILL_GENERATION', label: 'Em negociação' },
                { id: 'IN_PROGRESS', label: 'Aguardando pagamento' },
                { id: 'DONE', label: 'Concluída' },
                { id: 'CANCELLED', label: 'Cancelada' },
              ]}
              source="status" 
              label="Status"
            />
            <DateInput 
              onChange={(event) => setFilters(old => ({...old, date_start: event.target.value}))}
              defaultValue={filters.date_start}
              variant='outlined' 
              style={{ marginRight: '20px' }} 
              flex={1} 
              source="date_init" 
              label="Data inicial" 
            />
            <DateInput
              onChange={(event) => setFilters(old => ({...old, date_end: event.target.value}))}
              defaultValue={filters.date_end}
              variant='outlined' 
              flex={1} 
              source="date_end" 
              label="Data final" 
            />
          </div>
        </SimpleForm>
      </div>
      <div>
        {negotiations?.length > 0 ? (
          <ListContextProvider
            value={{
              data: keyBy(negotiations, 'id'),
              ids: negotiations.map(({ id }) => id),
              currentSort: { field: 'id', order: 'DESC' },
              selectedIds: [],
              total: pagination.total_items, 
              page: pagination.current_page, 
              perPage: perPage, 
              setPage: setPage,
              setPerPage: setPerPage,
              basePath: "/negotiations",
              resource: 'negotiations',
            }}
          >
            <div>
              <Card>
                <Datagrid>
                  <TextField 
                    label="Id" 
                    source="id" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Anúncio" 
                    source="ads.name" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Anunciante" 
                    source="ads.seller_broker.name" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Comprador" 
                    source="user.name" 
                    emptyText="Não informado" 
                  />

                  <FunctionField
                    label="Comissão anúnciante" 
                    render={
                      record => record?.negotiation_order?.commission_advertiser ?
                      toCalc(record?.negotiation_order?.commission_advertiser, record?.price) :
                       'Não informado'
                    }
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Comissão Comprador" 
                    render={
                      record => record?.negotiation_order?.commission_buyer ?
                      toCalc(record?.negotiation_order?.commission_buyer, record?.price) :
                       'Não informado'
                    }
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Comissão Agência de Corretagem" 
                    render={
                      record => record?.negotiation_order?.commission_company ?
                      toCalc(record?.negotiation_order?.commission_company, record?.price) :
                       'Não informado'
                    }
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Comissão Parceiro Estadual" 
                    render={
                      record => record?.negotiation_order?.commission_state_partner ?
                      toCalc(record?.negotiation_order?.commission_state_partner, record?.price) :
                        'Não informado'
                    }
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Comissão Parceiro Nacional" 
                    render={
                      record => record?.negotiation_order?.commission_country_partner ?
                      toCalc(record?.negotiation_order?.commission_country_partner, record?.price) :
                       'Não informado'
                    }
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Data de solicitação" 
                    render={record => Moment(record.created_at).format('DD/MM/yyyy HH:mm')}
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Valor" 
                    render={record => toCurrency(record.price)}
                    emptyText="Não informado"
                  />
                  <FunctionField
                    label="Status" 
                    render={record => statusPayment(record?.status)}
                    emptyText="Não informado"
                  />
                </Datagrid>
              </Card>
              <Pagination />
            </div>
          </ListContextProvider >
        ) : null}
      </div>
    </div>
  );
};

export default ReportList;
