import * as React from 'react';
import { Filter, SearchInput } from 'react-admin';

const SearchList = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export default SearchList;
