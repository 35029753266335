import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BrandList from './BrandList';
import BrandCreate from './BrandCreate';
import BrandEdit from './BrandEdit';
import BrandShow from './BrandShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "brands",
  options: { label: 'Marcas', singularLabel: 'Marca',  "menuParent": "settings" },
  list: BrandList,
  edit: BrandEdit,
  create: BrandCreate,
  show: BrandShow,
  icon: GroupWorkIcon,
};
