/* eslint-disable react/prop-types */
import * as React from "react";
import { Box, Toolbar } from "@material-ui/core";
import { SaveButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  muiButton: {
    color: "#ffffff",
  },
}));
const CreatePageToolbar = ({
  handleSubmitWithRedirect,
  invalid,
  saving,
  basePath,
}) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Box display="flex" justifyContent="flex-start" width="100%" pb="1.5em">
        <SaveButton
          className={classes.muiButton}
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          invalid={invalid}
          saving={saving}
          submitOnEnter
          redirect={basePath}
        />
      </Box>
    </Toolbar>
  );
};

export default CreatePageToolbar;
