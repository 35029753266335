/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useApi } from "../../context";
import {
  DateInput,
  SimpleForm,
} from 'react-admin';
import Moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { toCurrency } from "../../utils/maskInputs";

const ValueCard = ({value, name, loading, ...rest}) => (
  <Box
    style={{
      background: 'white',
      padding: '20px 30px',
      width: '150px',
      height: '90px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      marginRight: '20px'
    }}
    {...rest}
  >
    <Typography 
      sx={{
        fontSize: '20px', 
        fontWeight: 'bold',
      }}
    >
      {name}
    </Typography>
    <div style={{ marginTop: '27px' }}>
      
      <Typography 
        sx={{
          color: '#505255', 
          fontSize: '18px', 
          fontWeight: 'bold',
        }}
      >
        {!value || loading ? (<Skeleton />) : toCurrency(value)}
      </Typography>
    </div>
  </Box>
);

const InsuranceList = (props) => {
  const { apiUrl, fetchApi } = useApi();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateStart, setDateStart] = useState(Moment().startOf('month').format('yyyy-MM-DD'));
  const [dateEnd, setDateEnd] = useState(Moment().endOf('month').format('yyyy-MM-DD'));

  const getValues = async (start, end) => {
    try {
      setLoading(true);
      const date_start = start ?? Moment().startOf('month').format('yyyy-MM-DD');
      const date_end = end ?? Moment().endOf('month').format('yyyy-MM-DD');

      const url = (
        apiUrl + '/orders/financial_reports/simplified?' +
        new URLSearchParams({ date_start, date_end }).toString()
      );

      const response = await fetchApi(url);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setValues(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setValues({});
      console.warn(err);
    }
  };

  useEffect(() => {
    getValues();
  }, []);

  useEffect(() => {
    getValues(dateStart, dateEnd);
  }, [dateStart, dateEnd]);

  return (
    <div style={{marginLeft: '130px'}}>
      <Typography 
        sx={{
          color: '#505255', 
          fontSize: '18px', 
          fontWeight: 'bold',
          marginTop: '50px'
        }}
      >
        Filtro
      </Typography>
      <SimpleForm toolbar={false} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', width: '100%' }} >
          <DateInput 
            onChange={(event) => setDateStart(event.target.value)}
            defaultValue={dateStart}
            variant='outlined' 
            style={{ marginRight: '20px' }} 
            flex={1} 
            source="date_init" 
            label="Data inicial" 
          />
          <DateInput
            onChange={(event) => setDateEnd(event.target.value)}
            defaultValue={dateEnd}
            variant='outlined' 
            flex={1} 
            source="date_end" 
            label="Data final" 
          />
        </div>
      </SimpleForm>

      <Typography 
        sx={{
          color: '#505255', 
          fontSize: '18px', 
          fontWeight: 'bold',
          // marginTop: '50px'
        }}
      >
        Saldos no período selecionado:
      </Typography>
      
      {values?.autoconect ? (
        <div style={{ display: 'flex', marginTop: '50px' }}>
          <ValueCard value={values?.autoconect} loading={loading} name="Autoconect" />
          <ValueCard value={values?.fenacor} loading={loading} name="FENACOR" />
          {values?.sincor?.map((item, idx) => (
            <ValueCard key={idx} value={item.value} loading={loading} name={item.name} />
          ))}
        </div>
      ) : (
        <Typography 
          sx={{
            fontSize: '18px', 
            fontWeight: '400',
            marginTop: '20px'
          }}
        >
          Não há valores para o período informado
        </Typography>
      )}
    </div>
  );
};

export default InsuranceList;
