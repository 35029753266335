/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useApi } from "../../context";
import { 
  Create, 
  TextInput, 
  FormWithRedirect, 
  BooleanInput, 
  SelectInput,
  AutocompleteInput,
  required, 
  minLength, 
  maxLength,
  number,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import {
  CreatePageToolbar,
  PageTitle,
  ReturnActions,
} from '../../components';

const SelectWithSearchBanks = (props) => {
  const { apiUrl, fetchApi } = useApi();
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  const optionRenderer = choice => `${choice?.code} - ${choice?.name}`;

  const getBanks = async (search = '') => {
    try {
      setLoading(true);

      const response = await fetchApi(`${apiUrl}/banks?search=${search}&per_page=1000`);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw response;
      }
      setBanks(responseJson.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setBanks([]);
      console.warn(err);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <AutocompleteInput 
      source="bank_id" 
      label="Banco"
      loading={loading}
      choices={banks} 
      optionValue="id"
      optionText={optionRenderer}
      validate={props.validate}
      fullWidth
    />
  )
};

const BanksCreate = (props) => {
  const validateName = [required('Campo Obrigatório'), minLength(5, 'Insira pelo menos 6 caracteres')];
  const validateEan = [required('Campo Obrigatório'), minLength(11), maxLength(14, 'Número inválido')];
  const validateAccountType = [required('Campo Obrigatório')];
  const validateAccountAgency = [required('Campo Obrigatório'), number()];
  const validateAccount = [required('Campo Obrigatório'), number()];
  const validateAccountDigit = [required('Campo Obrigatório'), number(), maxLength(1)];
  const validateAccountBank = [required('Campo Obrigatório')];

  return (
    <Create
      title={
        <PageTitle
          label={props.options.singularLabel}
          type="create"
          record={props.record}
        />
      }
      actions={<ReturnActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={({ handleSubmitWithRedirect, invalid, saving, basePath }) => (
          <form>

            <Box p="1em">
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Registrar {props.options.singularLabel}
                  </Typography>

                  <Box flex={2} mr="1em">
                    <Typography variant="h6" gutterBottom>
                      Informações do titular
                    </Typography>
                    
                    <Box display="flex">
                      <Box flex={1} mr="0.5em" height="5em">
                        <TextInput
                          label="Nome"
                          source="owner_name"
                          validate={validateName}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="CPF/CNPJ"
                          source="owner_taxpayer_ein"
                          validate={validateEan}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box flex={2} mr="1em">
                    <Typography variant="h6" gutterBottom>
                      Informações da conta
                    </Typography>
                    
                    <Box display="flex">
                      <Box flex={1} mr="0.5em" height="5em">
                        <SelectInput
                          label="Tipo de conta"
                          source="account_type"
                          choices={[
                            { id: 'CONTA_CORRENTE', name: 'Conta corrente' },
                            { id: 'CONTA_POUPANCA', name: 'Conta Poupança' },
                          ]}
                          validate={validateAccountType}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} mr="0.5em" height="5em">
                        <TextInput
                          label="Agência"
                          source="agency"
                          validate={validateAccountAgency}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="Conta"
                          source="account"
                          validate={validateAccount}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" height="5em">
                        <TextInput
                          label="Dígito"
                          source="account_digit"
                          validate={validateAccountDigit}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Box mt="1em" display="flex">
                      <Box flex={1}>
                        <SelectWithSearchBanks validate={validateAccountBank} />
                      </Box>
                      <Box flex={1} mt="-2em" ml="1em">
                        <Typography variant="h6" gutterBottom>
                          Definir como conta principal
                        </Typography>
                        <Box height="5em" display="flex" alignItems="center">
                          <BooleanInput label="Conta principal" source="main_account" fullWidth />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
            <CreatePageToolbar
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              saving={saving}
              basePath={basePath}
            />
          </form>
        )}
      />
    </Create>
  );
  }

export default BanksCreate;
