import ReportList from "./ReportList";
import HandshakeIcon from '@mui/icons-material/Handshake';

const ReportView = {
  name: "negotiations",
  options: { label: "Negociações", singularLabel: "Relatório de negociações", "menuParent": "reports" },
  list: ReportList,
  icon: HandshakeIcon,
};

export default ReportView;
