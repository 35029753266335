import React from "react";
import { Box, Card, CardActions, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import { DirectionsCar } from "@material-ui/icons";

import publishArticleImage from "../../assets/svg/welcome_illustration.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.palette.type === "dark"
        ? "#F0781E"
        : "linear-gradient(to right, #F0781E 0%, #FC7F1E 35%), linear-gradient(to bottom, #F0781E 0%, #D66B1A 50%), #D66B1A",

    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "1em",
  },
  media: {
    background: `url(${publishArticleImage}) top right / cover`,
    marginLeft: "auto",
  },
  actions: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexWrap: "wrap",
      "& a": {
        marginTop: "1em",
        marginLeft: "0!important",
        marginRight: "1em",
      },
    },
  },
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom data-cy="typographyBemVindo">
            Bem Vindo ao AutoConect
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              App de anúncios de carros
            </Typography>
          </Box>
          <CardActions className={classes.actions}>
            <Button
              to
              variant="contained"
              href="/ads"
              startIcon={<DirectionsCar />}
            >
              Anúncios
            </Button>
            <Button
              variant="contained"
              href="/users?group_id=1,2"
              startIcon={<Person />}
            >
              Administradores
            </Button>
            {/* <Button variant="contained" href="/banners" startIcon={<Image />}>
              Banners
            </Button> */}
          </CardActions>
        </Box>

        <Box
          display={{ xs: "none", sm: "none", md: "block" }}
          className={classes.media}
          width="16em"
          height="9em"
          overflow="hidden"
        />
      </Box>
    </Card>
  );
};

export default Welcome;
