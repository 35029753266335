import ptBrMessages from "ra-language-pt-br";
import polyglotI18nProvider from "ra-i18n-polyglot";

const messages = {
  "pt-br": {
    ...ptBrMessages,
    ra: {
      ...ptBrMessages.ra,
      navigation: {
        ...ptBrMessages.ra.navigation,
        skip_nav: "Ir para o conteúdo",
      },
    },
    pos: {
      dashboard: {
        welcome: {
          title: "teste",
        },
      },
    },
  },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  "pt-br"
);

export default i18nProvider;
