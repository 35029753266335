import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { PageTitle, ReturnActions } from '../../components';

const BrandShow = (props) => (
  <Show
    {...props}
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="show"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
  >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

export default BrandShow;
