import AdsList from "./AdsList";
import AdsCreate from "./AdsCreate";
import AdsEdit from "./AdsEdit";
import { DirectionsCar } from "@material-ui/icons";

const AdsView = {
  name: "ads",
  options: { label: "Anúncios", singularLabel: "Anúncio" },
  list: AdsList,
  edit: AdsEdit,
  create: AdsCreate,
  icon: DirectionsCar,
};

export default AdsView;
