/* eslint-disable no-script-url */
import { Button, createTheme, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Login,
  SimpleForm,
  TextInput,
  email,
  useNotify,
} from "react-admin";
import { useUser } from "../../hooks/useUser";
import { bg } from "../../utils/bg";

const theme = createTheme({
  palette: {
    background: {default: '#F5F5F5', },
    secondary: {main: '#F0781E'},
    primary: {main: '#F0781E'},
  },
});

const RecoverPasswordPage: React.FC = () => {
  const notify = useNotify();
  const [data, setData] = useState({ email: "", token: "", new_password: "" });
  const [step, setStep] = useState("email");
  const { sendPasswordRecovery } = useUser();

  const handleChange = (key: string, value: string) => {
    setData((m) => ({ ...m, [key]: value }));
  };

  const handleSendEmail = async () => {
    try {
      await sendPasswordRecovery(data);
      setStep("sucess");
      notify("Senha enviada no email com sucesso", "success");
    } catch (error) {
      notify("Não foi possivel gerar uma senha para esse email, verifique se o usuario pertence a plataforma administrativa", "error");
    }
  };


  return (
    <Login theme={theme} backgroundImage={bg()}>
      {step === "email" && (
        <SimpleForm
          toolbar={<div />}
          initialValues={data}
          submitOnEnter={false}
          action="javascript: void(0)"
        >
          <Typography style={{ color: "#777" }}>
            Enviaremos uma senha de recuperação para o email abaixo
          </Typography>
          <TextInput
            onChange={(e) => handleChange("email", e.target.value)}
            source="Insira seu email"
            validate={[email("Email inválido")]}
          />
          <Button
            onClick={handleSendEmail}
            style={{ color: 'white', backgroundColor: theme.palette.primary.main }}
            variant="contained"
          >
            Enviar para email
          </Button>
          <br />
          <Button
            onClick={() => (window.location.href = "/login")}
            style={{ 
              borderWidth: 4,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              backgroundColor: 'white' 
            }}
            variant="contained"
          >
            Voltar ao login
          </Button>
            <br />
            <br />
        </SimpleForm>
      )}
      {step === "sucess" && (
       <SimpleForm
       toolbar={<div />}
       initialValues={data}
       submitOnEnter={false}
       action="javascript: void(0)"
     >
       <br />
       <Button
         onClick={() => (window.location.href = "/login")}
         style={{ color: 'white', backgroundColor: theme.palette.primary.main }}
         variant="contained"
       >
         Voltar ao login
       </Button>
         <br />
         <br />
     </SimpleForm>
      )}
    </Login>
  );
};

export default RecoverPasswordPage;
