import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ColorList from './ColorList';
import ColorCreate from './ColorCreate';
import ColorEdit from './ColorEdit';
import ColorShow from './ColorShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "colors",
  options: { label: 'Cores', singularLabel: 'Cor',  "menuParent": "settings" },
  list: ColorList,
  edit: ColorEdit,
  create: ColorCreate,
  show: ColorShow,
  icon: GroupWorkIcon,
};
