/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext } from "react";

import { useEnvironment, useLocalStorage, headers } from ".";

const LOGIN_ROUTE = "/login";

export const ApiContext = createContext({
  apiUrl: "",
  refreshToken: async () => {},
  fetchApi: async () => {},
  adminAuthProvider: {
    login: async ({ _username, _password }) => {},
    logout: async () => {},
    checkError: async ({ _status }) => {},
    checkAuth: async () => {},
    getPermissions: async () => {},
  },
});

const AuthContextProvider = ({ children }) => {
  const { api, tokens } = useEnvironment();
  const {
    getAuthTokens,
    clean: cleanLocalStorage,
    persistContent,
  } = useLocalStorage();

  const API_BASE_URL = `${api.host}:${api.port}`;
  const apiUrl = `${API_BASE_URL}/${api.version}/${api.path}`;

  const refreshToken = async () => {
    const url = `${API_BASE_URL}/auth/refresh`;
    const { refreshToken } = getAuthTokens();

    const options = {
      method: "POST",
      headers: {
        [headers.ACCEPT]: "application/json",
        [headers.CONTENT_TYPE]: "application/json",
        [headers.AUTHORIZATION]: `Bearer ${refreshToken}`,
      },
    };

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (response.status !== 200) {
      cleanLocalStorage();
      throw new Error("Error while trying to fetch the API");
    } else {
      persistContent(responseJson);
      Promise.resolve();
    }
  };

  const fetchApi = async (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers();
      options.headers.set(headers.ACCEPT, "application/json");
    }

    const { accessToken } = getAuthTokens();
    options.headers.set(headers.AUTHORIZATION, `Bearer ${accessToken}`);
    const response = await fetch(url, options);

    if (response.status === 401) {
      try {
        await refreshToken();
      } catch (err) {
        console.error(err);
        Promise.reject({ redirectTo: LOGIN_ROUTE });
      }

      return fetchApi(url, options);
    }
    return response;
  };

  const adminAuthProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
      try {
        const response = await fetch(`${API_BASE_URL}/auth/token`, {
          method: "POST",
          headers: {
            [headers.AUTHORIZATION]: tokens.admin,
            [headers.ACCEPT]: "application/json",
            [headers.CONTENT_TYPE]: "application/json",
          },
          body: JSON.stringify({
            username,
            password,
            grant_type: "password",
          }),
        });

        const authTokens = await response.json();
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        persistContent(authTokens);
      } catch (e) {
        console.log(e);
        return Promise.reject();
      }
    },
    // called when the user clicks on the logout button
    logout: async () => {
      cleanLocalStorage();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        cleanLocalStorage();
        return Promise.reject({ redirectTo: LOGIN_ROUTE });
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () =>
      getAuthTokens()
        ? Promise.resolve()
        : Promise.reject({ redirectTo: LOGIN_ROUTE }),
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
  };

  return (
    <ApiContext.Provider
      value={{
        apiUrl,
        refreshToken,
        fetchApi,
        adminAuthProvider,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);

export default AuthContextProvider;
