import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  SimpleList,
  List,
  Datagrid,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  SelectInput,
  SearchInput,
  downloadCSV,
  // DateInput,
  DateField,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { PageTitle, ListActions } from '../../components';
import jsonExport from 'jsonexport/dist';
import { statusPartner } from '../../utils/maskInputs';

const PartnerType = () => {
  const record = useRecordContext();
  return (
    <>
      {record.type === "country_partner" && "Parceiro Nacional"}
      {record.type === "state_partner" && "Parceiro Estadual"}
      {record.type === null && 'Não identificado'}
    </>
  );
};

const FilterPartner = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="group"
      label="Grupo"
      choices={[
        { id: '0', name: 'Todos' },
        // { id: '1', name: '1' },
        // { id: '2', name: '2' },
      ]}
    />
    {/* <DateInput source="created_at_after" label="Cadastrado desde" />
    <DateInput source="created_at_before" label="Cadastrado antes" /> */}
  </Filter>
);
const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      cell_phone,
      status,
      cpf,
      social_media,
      config,
      image,
      group,
      hash_id,
      ...postForExport
    } = user;
    return postForExport;
  });

  jsonExport(
    usersForExport,
    {
      headers: ['id', 'name', 'email', 'created_at'],
    },
    (err, csv) => {
      downloadCSV(csv, 'users');
    }
  );
};

const PartnerList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
        />
      }
      bulkActionButtons={false}
      filters={<FilterPartner />}
      actions={<ListActions isCreate />}
      exporter={exporter}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={({ name }) => name}
          secondaryText={({ email }) => email}
          tertiaryText={({ cell_phone }) => cell_phone}
        />
      ) : (
        <Datagrid>
          <TextField label="ID" source="id" emptyText="Não informado"/>
          <TextField label="Nome" source="name" emptyText="Não informado"/>
          <FunctionField
            label="Status" 
            render={record => statusPartner(record.status)}
              source="status" emptyText="Não informado"
          />
          <TextField label="Razão Social" source="social_name" emptyText="Não informado"/>
          <TextField label="CNPJ" source="ean" emptyText="Não informado"/>
          <PartnerType
            label="Tipo de Parceiro"
            record={props.record}
            source="type"
          />
          <FunctionField
            label="Split"
            source='Split'
            emptyText="Não informado"
            render={(record) =>
              record.split ? 'Ativado' : 'Desativado' }
          />
          <TextField label="Estado" source="address.city.state.name" emptyText="Não informado"/>
          <TextField label="Telefone" source="cell_phone" emptyText="Não informado"/>
          <EmailField label="e-mail" source="email" emptyText="Não informado"/>
          <DateField label="Data entrada" source="created_at" emptyText="Não informado"/>
          <EditButton label="" />
          <DeleteButton label="" />
        </Datagrid>
      )}
    </List>
  );
};

export default PartnerList;
