/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Textfield from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import {
  Datagrid,
  TextField,
  FunctionField,
  ListContextProvider,
} from 'react-admin';
import keyBy from 'lodash/keyBy'
import Moment from 'moment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Skeleton from '@mui/material/Skeleton';
import { useWallet } from "../../hooks/useWallet";
import { toCurrency, statusAsaas } from "../../utils/maskInputs";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ResponseModal({
  isFinished,
  isOk,
  buttonText,
  loading,
  successMsg,
  errorMsg,
  onSubmit,
  disabled,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isFinished) {
      handleOpen()
    }
  }, [isFinished])

  return (
    <>
      <Button 
        onClick={onSubmit}
        loading={loading}
        disabled={loading || disabled}
        variant="contained"
        sx={{color: '#FFF', fontWeight: 'bold'}}
      >
        {buttonText}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box 
          sx={{ 
            ...style, 
            width: 300, 
            borderRadius: '20px', 
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div id="child-modal-title">
            {isOk ? (
              <CheckCircleOutlineIcon
                style={{
                  width: '150px',
                  height: '150px',
                  color: '#008A00',
                }}
              />
            ) : (
              <DangerousIcon
                style={{
                  width: '150px',
                  height: '150px',
                  color: '#EB0000',
                }}
              />
            )}
          </div>
          <h1 id="child-modal-description" style={{ textAlign: 'center' }}>
            {isOk ? successMsg : errorMsg}
          </h1>
          <Button onClick={handleClose}>Voltar</Button>
        </Box>
      </Modal>
    </>
  );
}

const Wallet = () => {
  const [open, setOpen] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [isOk, setIsOk] = useState(false);

  const { 
    balance, 
    transfers, 
    loading, 
    mainBankAccount, 
    getBalance, 
    getTransfers, 
    getMainAccount,
    createTransfer,
  } = useWallet();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setIsFinished(false)
    const res = await createTransfer(mainBankAccount[0].id, Number(withdrawValue))
    setIsOk(res?.ok)
    setIsFinished(true)
  };

  useEffect(() => {
    getBalance();
    getTransfers();
    getMainAccount();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400, borderRadius: '20px', border: 'none' }}>
          <h2 id="parent-modal-title">Solicitar saque</h2>

          {mainBankAccount[0] ? (
            <>
              <ListContextProvider 
                value={{
                  data: keyBy(mainBankAccount, 'id'),
                  ids: mainBankAccount.map(({ id }) => id),
                  currentSort: { field: 'id', order: 'DESC' },
                  basePath: "/posts",
                  resource: 'posts',
                  selectedIds: []
                }}
              >
                <strong id="parent-modal-description">
                  Informações do titular:
                </strong>
                <Datagrid>
                  {/* <TextField 
                    label="Id" 
                    source="id" 
                    emptyText="Não informado" 
                  /> */}
                  <TextField 
                    label="Nome do titular" 
                    source="owner_name" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="CPF/CNPJ" 
                    source="owner_taxpayer_ein" 
                    emptyText="Não informado" 
                  />
                </Datagrid>

                <br/>

                <strong id="parent-modal-description">
                  Informações da conta:
                </strong>

                <Datagrid>
                  <TextField 
                    label="Banco" 
                    source="bank.name" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Agência" 
                    source="agency" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Conta" 
                    source="account" 
                    emptyText="Não informado" 
                  />
                  <TextField 
                    label="Dígito" 
                    source="account_digit" 
                    emptyText="Não informado" 
                  />
                </Datagrid>
              </ListContextProvider >
              
              <p style={{marginTop: '30px'}} id="parent-modal-description">
                Valor disponível para saque: 
                <strong style={{ color: '#008A00' }}>{` ${toCurrency(balance?.balance)}`}</strong>
              </p>

              <NumberFormat
                customInput={Textfield}
                onValueChange={(values) => setWithdrawValue(values.value)}
                label="Valor do saque"
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                fullWidth
                required
                sx={{marginBottom: '20px'}}
                variant="outlined"
              />
            </>
          ) : (
            <p>Não há bancos cadastrados</p>
          )}
          
          <Box sx={{display:'flex', justifyContent: 'space-between'}}>
            <Button onClick={handleClose}>Cancelar</Button>
            <ResponseModal
              disabled={!mainBankAccount[0]}
              isFinished={isFinished}
              isOk={isOk}
              buttonText="Solicitar saque"
              loading={loading}
              successMsg="Saque solicitado com sucesso"
              errorMsg="Algo deu errado"
              onSubmit={handleSubmit}
            />
          </Box>
        </Box>
      </Modal>
      <div style={{margin: '20px 30px 50px 100px'}}>
        <div style={{margin: '30px', display: 'flex'}} data-cy="divCards">
          <Box
            style={{
              background: 'white',
              padding: '20px 30px',
              width: '200px',
              height: '160px',
              marginRight: '30px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <AttachMoneyIcon sx={{color: '#0A9330', fontSize: '2em'}} />
            <div>
              <Typography 
                sx={{
                  color: '#8B8E93', 
                  fontSize: '16px', 
                  fontWeight: '400',
                }}
              >
                Saldo disponível
              </Typography>
              <Typography 
                sx={{
                  color: '#505255', 
                  fontSize: '18px', 
                  fontWeight: '400',
                }}
              >
                {!balance?.balance ? (<Skeleton />) : toCurrency(balance?.balance)}
              </Typography>
            </div>
            <Link
              color="#0A9330"
              sx={{cursor: 'pointer'}}
              onClick={handleOpen}
            >
              Sacar
              {/* <AiOutlineArrowRight style={{ marginLeft: '5px' }} /> */}
            </Link>
          </Box>

          <Box
            style={{
              background: 'white',
              padding: '20px 30px',
              width: '200px',
              height: '160px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <LockOpenIcon sx={{color: '#0A9330', fontSize: '2em'}} />
            <div style={{ marginTop: '27px' }}>
              <Typography 
                sx={{
                  color: '#8B8E93', 
                  fontSize: '16px', 
                  fontWeight: '400',
                }}
              >
                Saldo bloqueado
              </Typography>
              <Typography 
                sx={{
                  color: '#505255', 
                  fontSize: '18px', 
                  fontWeight: '400',
                }}
              >
                {!balance?.pending ? (<Skeleton />) : toCurrency(balance?.pending)}
              </Typography>
            </div>
          </Box>
        </div>
      </div>

      <div style={{marginLeft: '130px'}}>
        {transfers?.length > 0 ? (
          <ListContextProvider 
            value={{
              data: keyBy(transfers, 'id'),
              ids: transfers.map(({ id }) => id),
              currentSort: { field: 'id', order: 'DESC' },
              basePath: "/posts",
              resource: 'posts',
              selectedIds: []
            }}
          >
            <Datagrid>
              <TextField 
                label="Id" 
                source="id" 
                emptyText="Não informado" 
              />
              <FunctionField
                label="Data de solicitação" 
                render={record => Moment(record.created_at).format('DD/MM/yyyy HH:mm')}
                  source="created_at" emptyText="Não informado"
              />
              <FunctionField
                label="Valor" 
                render={record => toCurrency(record.value)}
                  source="value" emptyText="Não informado"
              />
              <FunctionField
                label="Situação" 
                render={record => statusAsaas(record?.status)}
                  source="status" emptyText="Não informado"
              />
              <FunctionField
                label="Data de efetivação" 
                render={
                  record => record.date_effective ? 
                    Moment(record.date_effective).format('DD/MM/yyyy HH:mm') 
                    : '-'
                  }
                  source="date_effective" emptyText="-"
              />
              <TextField 
                label="Descrição" 
                source="description" 
                emptyText="Sem descrição" 
              />
            </Datagrid>
          </ListContextProvider >
        ) : null}
      </div>
    </>
  );
};

export default Wallet;
