/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Edit, TextInput, BooleanInput, FormWithRedirect, SelectInput } from 'react-admin';

import {
  ReturnActions,
  EditPageToolbar,
  PageTitle,
} from '../../components';

const BanksEdit = (props) => {
  
return (
  <Edit
    title={
      <PageTitle
        label={props.options.singularLabel}
        type="edit"
        record={props.record}
      />
    }
    actions={<ReturnActions />}
    {...props}
  >
    <FormWithRedirect
      {...props}
      render={({
        basePath,
        handleSubmitWithRedirect,
        invalid,
        resource,
        saving,
        record,
      }) => (
        <form>
          <Box p="1em">
            <Box flex={2} mr="1em">
              <Typography variant="h6" gutterBottom>
                Informações do titular
              </Typography>
              
              <Box display="flex">
                <Box flex={1} mr="0.5em" height="5em">
                  <TextInput
                    disabled
                    label="Nome"
                    source="owner_name"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em" height="5em">
                  <TextInput
                    disabled
                    label="CPF/CNPJ"
                    source="owner_taxpayer_ein"
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Informações da conta
                </Typography>
                
                <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                    <SelectInput
                      label="Tipo de conta"
                      source="account_type"
                      choices={[
                        { id: 'CONTA_CORRENTE', name: 'Conta corrente' },
                        { id: 'CONTA_POUPANCA', name: 'Conta Poupança' },
                      ]}
                      fullWidth
                      disabled
                    />
                  </Box>
                  <Box flex={1} mr="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Agência"
                      source="agency"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Conta"
                      source="account"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml="0.5em" height="5em">
                    <TextInput
                      disabled
                      label="Dígito"
                      source="account_digit"
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box mt="1em" display="flex">
         
                  <Box flex={1} mt="-2em" ml="1em">
                    <Typography variant="h6" gutterBottom>
                      Definir como conta principal
                    </Typography>
                    <Box height="5em" display="flex" alignItems="center">
                      <BooleanInput label="Conta principal" source="main_account" fullWidth />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <EditPageToolbar
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            basePath={basePath}
            saving={saving}
            record={record}
            resource={resource}
          />
        </form>
      )}
    />
  </Edit>
);
}

export default BanksEdit;
