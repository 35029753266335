import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  useRecordContext
} from 'react-admin';
import { PageTitle } from '../../components';
import InfoIcon from '@material-ui/icons/Info';
import { styled, Box } from '@mui/system';
import { Button, Modal } from '@mui/material';

const PercentStructure = ({ source }) => {
  const record = useRecordContext();
  return `${record[source]}%`;
};

const ComissionTotal = () => {
  const record = useRecordContext();
  return `${parseFloat(
    record.commission_company +
      record.commission_advertiser +
      record.commission_buyer
  )}%`;
};

const CompanyList = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const Backdrop = styled("div")`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

  const style = {
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 3,
    p: 2,
    px: 4,
    pb: 3,
  };

  return (
    <List
      title={
        <PageTitle
          label={props.options.label}
          type="list"
          record={props.record}
          />
        }
        bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <TextField label="Nome" source="name" emptyText="Não informado" />
        <TextField
          label="Razão Social"
          source="company_name"
          emptyText="Não informado"
        />
        <TextField label="CNPJ" source="ein" emptyText="Não informado" />
        <TextField label="Corretora Padrão" source="brokerage_agency.name" emptyText="Corretora Autoconect" />
        <TextField
          label="Estado"
          source="address.city.name"
          emptyText="Não informado"
        />
        <TextField
          label="Cidade"
          source="address.city.state.name"
          emptyText="Não informado"
        />
        <NumberField
          label="Taxa Boleto"
          source="price_bill_registry"
          options={{ style: "currency", currency: "BRL" }}
          emptyText="Não informado"
        />
        <PercentStructure
          label="Comissão Autoconect"
          record={props.record}
          source="commission_company"
          emptyText="Não informado"
        />
        <PercentStructure
          record={props.record}
          label="Comissão Nacional"
          source="commission_country_partner"
          emptyText="Não informado"
        />
        <PercentStructure
          record={props.record}
          label="Comissão Estadual"
          source="commission_state_partner"
          emptyText="Não informado"
        />
        <PercentStructure
          record={props.record}
          label="Comissão Vendedor"
          source="commission_advertiser"
          emptyText="Não informado"
        />
        <PercentStructure
          record={props.record}
          label="Comissão Comprador"
          source="commission_buyer"
          emptyText="Não informado"
        />
        <ComissionTotal
          record={props.record}
          label="Comissão Total"
          emptyText="Não informado"
        />

        <div>
          <Button type="button" onClick={handleOpen}>
            <InfoIcon style={{ marginRight: 5 }} onClick={handleOpen} />
            Info
          </Button>
          <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
          >
            <Box sx={style}>
              <h2 id="unstyled-modal-title">Comissões Estadual e Nacional</h2>
              <p id="unstyled-modal-description">
                Sincor é 1,25% de 1% de comissão do autoconect.
              </p>
              <p id="unstyled-modal-description">
                Ou seja, por exemplo a uma taxa avaliada em 600$ ao autoconect:
              </p>
              <p id="unstyled-modal-description">(1% 600,00) = R$ 7,50</p>
              <br />
              <p id="unstyled-modal-description">
                Senacor é 0,75% de 1% de comissão do autoconect
              </p>
              <p id="unstyled-modal-description">
                Ou seja, por exemplo a uma taxa avaliada em 600$ ao autoconect:
              </p>
              <p id="unstyled-modal-description">(1% 600,00) = R$ 4,50</p>
            </Box>
          </StyledModal>
        </div>
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
