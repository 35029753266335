import CardWithIcon from "../../components/CardWithIcon";
import React, { useEffect } from "react";
import UserIcon from "@material-ui/icons/Group";
import Person from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HandshakeIcon from '@mui/icons-material/Handshake';
import { HomeWork } from "@material-ui/icons";
import { stringify } from "query-string";
import { useDashboard } from "../../hooks/dashboard";
import Welcome from "./Welcome";

<Typography variant="h1" component="h2">
  h1. Título
</Typography>;

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;

const Dashboard = () => {
  const { dashboard, loading, getDashboard } = useDashboard();
  useEffect(() => {
    getDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === true) {
    return (
      <>
        <Welcome />
      </>
    );
  }
  return (
    <>
      <Welcome />
      {!loading && (
        <>
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <div style={styles.flex} data-cy="divCards">
                <CardWithIcon
                  to={{
                    pathname: "/brokerage_agencies",
                  }}
                  icon={HomeWork}
                  title="Corretoras"
                  subtitle={dashboard ? dashboard?.brokerage_agency?.total : 0}
                />
                <Spacer />
                <CardWithIcon
                  to={{
                    pathname: "/users",
                    search: stringify({
                      page: 1,
                      perPage: 25,
                      sort: "shared_count",
                      order: "DESC",
                      filter: {},
                    }),
                  }}
                  icon={Person}
                  title="Responsáveis Ativos"
                  subtitle={dashboard ? dashboard?.users?.administrators : 0}
                />
                <Spacer />
                <CardWithIcon
                  to={{
                    pathname: "/producers",
                    state: {
                      filterValues: { group: "4" },
                      displayedFilters: { group: true },
                    },
                  }}
                  icon={UserIcon}
                  title="Produtores Ativos"
                  subtitle={dashboard ? dashboard?.users?.producers_active : 0}
                />
                <Spacer />
                <CardWithIcon
                  to="/ads"
                  icon={DirectionsCarIcon}
                  title="Anúncios Ativos"
                  subtitle={dashboard ? dashboard?.ads?.active : 0}
                />
                <Spacer />
                <CardWithIcon
                  to={{
                    pathname: "negotiations",
                  }}
                  icon={HandshakeIcon}
                  title="Negociações em andamento"
                  subtitle={dashboard?.negotiation ?? 0}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
