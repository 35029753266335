import React, { useState } from 'react';
import { Typography, Box, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Edit, TextInput, BooleanInput, FormWithRedirect } from 'react-admin';

import {
  ReturnActions,
  EditPageToolbar,
  PageTitle,
} from '../../components';

const ClientEdit = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Edit
      title={
        <PageTitle
          label={props.options.singularLabel}
          type="edit"
          record={props.record}
        />
      }
      actions={<ReturnActions />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={({
          basePath,
          handleSubmitWithRedirect,
          invalid,
          resource,
          saving,
          record,
        }) => (
          <form>
            <Box p="1em">
              <Box display="flex" flexDirection={'column'}>
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Alterar Seguradora
                  </Typography>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput disabled label="Nome" source="name" fullWidth />
                    </Box>
                    <Box flex={1} mt="-2em" ml="1em">
                      <Typography variant="h6" gutterBottom>
                        Status
                      </Typography>
                      <Box height="5em" display="flex" alignItems="center">
                        <BooleanInput label="Ativo" source="status" fullWidth />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box flex={2} mr="1em">
                  <Typography variant="h6" gutterBottom>
                    Alterar Senha
                  </Typography>
                  <Box display="flex">
                  <Box flex={1} mr="0.5em" height="5em">
                      <TextInput
                      label="E-mail"
                      source="email"
                      type="email"
                      fullWidth
                    />
                    </Box>
                    <Box flex={1} mr="0.5em" height="5em">
                      <TextInput
                        label="Alterar a senha"
                        source="password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <EditPageToolbar
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              basePath={basePath}
              saving={saving}
              record={record}
              resource={resource}
            />
          </form>
        )}
      />
    </Edit>
  );
};

export default ClientEdit;